// 引入依赖
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import { UNIVERSE } from "../constants/universe";
import LocalStorageService from "../service/LocalStorageService";
import SchoolDatabase from "../service/SchoolDatabase";
import {
  forEach,
  noEmpty,
  normalize,
  find,
  flatten,
  map,
  prop,
} from "../utils/fp";

// 定义状态存储类型
export type Store = {
  schools: any[]; // 用于存储所有学校信息的状态
  schoolMap: any; // 用于存储学校映射表的状态
  getUniverse: () => void; // 获取universe的方法
  updateSchool: (school: any) => void; // 更新学校信息的方法
  getCharacter: (id: string | null) => any; // 获取角色信息的方法
};
// 新建SchoolDatabase实例
const db = new SchoolDatabase();
// 创建状态存储
export const useUniverseStore = create<Store>((set, get) => ({
  schools: LocalStorageService.getItem(UNIVERSE), // 使用LocalStorage中的UNIVERSE信息初始化schools状态
  schoolMap: {}, // 初始化schoolMap状态为空对象
  getUniverse: () =>
    // 定义获取universe的方法
    AxiosService.get("/api/get-universe").then((res: any) => {
      if (noEmpty(res)) {
        // 如果获取到的response不为空，则把该数据存储到LocalStorage和school状态中，同时更新schoolMap状态
        LocalStorageService.setItem(UNIVERSE, res);
        set({ schools: res, schoolMap: normalize(res) });
        forEach((item: any) => {
          // 遍历response中的每一项，获取其相应的数据并更新到schoolMap状态和SchoolDatabase中
          db.get(item.id).then((characters: any) => {
            if (res) {
              set({
                schoolMap: {
                  ...get().schoolMap,
                  [item.id]: characters,
                },
              });
            }
          });
          AxiosService.get(`/api/get-school?id=${item.id}`).then(
            (characters: any) => {
              set({
                schoolMap: {
                  ...get().schoolMap,
                  [item.id]: {
                    ...item,
                    characters,
                  },
                },
              });
              db.upsert({ ...item, characters });
            }
          );
        })(res);
      }
    }),
  updateSchool: (id) => {
    // 定义更新学校信息的方法
    AxiosService.get(`/api/get-school?id=${id}`).then((characters: any) => {
      const item = get().schoolMap[id];
      set({
        schoolMap: {
          ...get().schoolMap,
          [id]: {
            ...item,
            characters,
          },
        },
      });
      db.upsert({ id, characters });
    });
  },
  getCharacter: (id: string | null) => {
    if (!id) return {};
    const list = flatten(map(prop("characters"))(get().schoolMap));
    return find((item: any) => item?.id === id)(list);
  },
}));
