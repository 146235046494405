// 聊天信息
import { memo, useCallback, useMemo } from "react";
import { Dropdown, Avatar } from "antd";
import {
  DeleteOutlined,
  ExportOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { shallow } from "zustand/shallow";

import CharacterCard from "../../../../components/Character/Card";
import { useDeleteChat } from "../../hooks/useDeleteChat";
import { findModel } from "../Model";
import { useGo } from "../../../../hooks/useGo";
import { prop } from "../../../../utils/fp";
import { useCharacterStore } from "../../../../store/character";
import { getAvatrUrl } from "../../../../utils/avatar";

import { useChatStore } from "../../store/index";

const Info = ({ chatId }: any) => {
  const info = useChatStore(
    (store) => prop(`chatMap.${chatId}.info`)(store) || {},
    shallow
  );

  const { onDelete } = useDeleteChat();
  const { go } = useGo();
  // console.log(info, chatId, "info");

  const onGo = useCallback(() => {
    go(`/chat/${info?.id}`);
  }, [go, info]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <DeleteOutlined />,
      danger: true,
      label: <div onClick={onDelete(info)}>清空聊天记录</div>,
    },
    {
      key: "2",
      label: <div onClick={onGo}>在新标签页打开</div>,
      icon: <ExportOutlined />,
    },
  ];

  const model = useMemo(() => findModel(info?.model), [info?.model]);

  const { characterMap } = useCharacterStore();

  return (
    <div className="border-b   w-full z-[10]  p-4  flex items-center justify-between bg-[#f3f3f3] ">
      <div className="flex items-center ">
        <Avatar.Group maxCount={2}>
          <CharacterCard id={info?.role || "0"}>
            <Avatar
              shape="square"
              className="border-0"
              src={getAvatrUrl(prop(`${info?.role || "0"}`)(characterMap))}
            >
              {prop(`${info?.role || "0"}.nickname`)(characterMap)}
            </Avatar>
          </CharacterCard>
        </Avatar.Group>
       
        <CharacterCard id={info?.role || "0"}>
          <div className="ml-[10px] overflow-hidden h-[26px]">
            {prop(`${info.role || "0"}.nickname`)(characterMap)}
          </div>
        </CharacterCard>
        {model?.name && (
          <div className="ml-1 text-[10px] text-gray-500  rounded-[20px] bg-white py-1 px-3">
            {model?.name}
          </div>
        )}
      </div>
      <div className="flex   items-center justify-between ">
        <Dropdown menu={{ items }}>
          <EllipsisOutlined className=" cursor-pointer text-[24px]" />
        </Dropdown>
      </div>
    </div>
  );
};

export default memo(Info);
