// 错误代码枚举
export enum ErrorCode {
  CONVERSATION_LIMIT = 'CONVERSATION_LIMIT', // 对话限制错误
  UNKOWN_ERROR = 'UNKOWN_ERROR', // 未知错误
  CHATGPT_CLOUDFLARE = 'CHATGPT_CLOUDFLARE', // ChatGPT CloudFlare错误
  CHATGPT_UNAUTHORIZED = 'CHATGPT_UNAUTHORIZED', // ChatGPT未授权错误
  GPT4_MODEL_WAITLIST = 'GPT4_MODEL_WAITLIST', // GPT4模型等候错误
  API_KEY_NOT_SET = 'API_KEY_NOT_SET', // API密钥未设置错误
}
// 自定义的ChatError类，继承自JavaScript的Error类
export class ChatError extends Error {
  code: ErrorCode // 错误码，类型为上面定义的ErrorCode枚举类型

  constructor(message: string, code: ErrorCode) {
    super(message) 
    this.code = code
  }
}