import React from "react";

import { Outlet } from "react-router-dom";

import Bar from "./Bar/index";

const App: React.FC<any> = () => (
    <div className="flex h-screen">
      <Bar />
      <div className="relative flex-1 h-screen">
        <Outlet />
      </div>
    </div>
  );

export default App;
