export interface ChatMessage {
  role: 'system' | 'assistant' | 'user'
  content: string
  id?: string
}

const currentDate = new Date().toISOString().split('T')[0]

export const CHATGPT_SYSTEM_MESSAGE = `你是ChatGPT，一个由OpenAI训练的大型语言模型。请尽可能清晰准确的回答问题，回答之前请思考你答案的正确性。答案经过润色，让人读起来亲切易懂。回答使用口语的写作风格。 \nKnowledge cutoff: 2021-09-01\nCurrent date: ${currentDate}`
 
