import { useCallback } from "react";
import { Divider, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { LanguageSetting } from "../../components/Header/LanguageSetting";
import { useUserStore } from "../../store/user";
import LocalStorageService from "../../service/LocalStorageService";
import { TOKEN_KEY, USER_INFO } from "../../constants/user";
import BaseInfo from "./components/BaseInfo";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setUser } = useUserStore();

  const logout = useCallback(() => {
    setUser(null);
    LocalStorageService.removeItem(TOKEN_KEY);
    LocalStorageService.removeItem(USER_INFO);
    navigate("/sign");
  }, [setUser, navigate]);

  return (
    <div className="p-4">
      <BaseInfo>
        <Button
          type="primary"
          className="bg-[#ff2d55!important]  border-[#ff2d55!important] ml-2"
          onClick={() => navigate("/profile/recharge")}
        >
          充值
        </Button>
      </BaseInfo>

      <Divider />

      <Button className="" onClick={() => logout()}>
        {t("logout")}
      </Button>
    </div>
  );
};

export default Profile;
