// 引入相关的库和定义一些接口
import { parseSSEResponse } from "../../utils/sse";
import type { SendMessageParams } from "../abstract-bot";
import { AbstractBot, EventType } from "../abstract-bot";
import type { ChatMessage } from "../constants";
import { CHATGPT_SYSTEM_MESSAGE } from "../constants";
import { takeRight, forEach } from "../../utils/fp";
import { DEFAULT_MODEL } from "../../constants/model";
import { OPEN_AI_HOST } from "./constants";
import { TOKEN_KEY } from "../../constants/user";
import LocalStorageService from "../../service/LocalStorageService";

interface ApiConfig {
  model?: string;
  temperature?: number;
  system?: string;
}
// 定义聊天机器人的配置
const chatgptApiTemperature = 1;

const CONTEXT_SIZE = 2; // 上下文大小

 export  const makeContext = (
  messages: ChatMessage[],
  prompt: string,
  system = CHATGPT_SYSTEM_MESSAGE
) => {
  const list = [{ role: "system", content: system }];
  forEach((item: any) => {
    if (item.answer) {
      list.push({ role: "user", content: item.question });
      list.push({ role: "assistant", content: item.answer });
    }
  })(takeRight(CONTEXT_SIZE)(messages));
  list.push({ role: "user", content: prompt });
  return list;
};


// 定义ChatGPTApiBot类
export class ChatGPTApiBot extends AbstractBot {
  // 发送请求到预测 API
  async fetchCompletionApi(
    messages: any[],
    config?: any,
    signal?: AbortSignal,
    messageId?: any
  ) {
    const token = LocalStorageService.getItem(TOKEN_KEY);
    const resp = await fetch(`${OPEN_AI_HOST}/v1/chat/completions`, {
      method: "POST",
      signal,
      headers: { "Content-Type": "application/json", T: token, "M-ID": messageId },
      body: JSON.stringify({
        model: config?.model || DEFAULT_MODEL,
        messages,
        temperature: config?.temperature || chatgptApiTemperature,
        stream: true,
      }),
    });

    return resp;
  }

  // 发送信息
  async doSendMessage(params: SendMessageParams, config?: ApiConfig) {
    const resp = await this.fetchCompletionApi(
      params.context,
      config,
      params.signal,
      params.id 
    );
    let done = false;
    const { headers } = resp;
    const result: ChatMessage = { role: "assistant", content: "" };

    const finish = () => {
      done = true;
      params.onEvent({ type: EventType.DONE, id: headers.get("X-id") || "" });
    };
    await parseSSEResponse(resp, (message) => {
      if (message === "[DONE]") {
        finish();
        return;
      }
      let data;
      try {
        data = JSON.parse(message);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }

      if (data?.choices?.length) {
        const { delta } = data.choices[0];
        if (delta?.content) {
          result.content += delta.content;
          params.onEvent({
            type: EventType.UPDATE_ANSWER,
            data: { text: result.content },
          });
        }
      }
    });
    if (!done) finish(); // 如果没有完成，那么先完成
  }
}
