// 生产@列表

import { Avatar } from "antd";
import { useMemo } from "react";
import { useCharacterStore } from "../../../../../store/character";
import { map } from "../../../../../utils/fp";
import { getAvatrUrl } from "../../../../../utils/avatar";

export const useAt = () => {
  const { character } = useCharacterStore();
  const atOptions = useMemo(
    () =>
      map((item: any) => ({
        value: item.nickname,
        label: (
          <div>
            <Avatar className="mr-1" shape="square" src={getAvatrUrl(item)} />
            {item.nickname}
          </div>
        ),
      }))(character),
    [character]
  );
  return  atOptions;
};
