// 问题
import { useCallback, memo, useEffect, useMemo } from "react";
import { Avatar } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { prop, flow, uniq, compact } from "../../../../utils/fp";

import { updateChatApi } from "../../../../api/chat";
import { useUserStore } from "../../../../store/user";
import { getAvatrUrl } from "../../../../utils/avatar";
import { useChatStore } from "../../store/index";

import { excludeByIds } from "../../utils";

const Question = ({
  chatId,
  goBottom,
  question,
  lastQuestionId,
  isScrollBottom,
}: any) => {
  const { user } = useUserStore();

  const { chatMap, refreshChat } = useChatStore();

  const chat = useMemo(() => prop(chatId)(chatMap), [chatId, chatMap]);

  const sending = useMemo(
    () => prop(`${chatId}.sending`)(chatMap),
    [chatId, chatMap]
  );

  const info = useMemo(() => prop("info")(chat) || {}, [chat]);

  useEffect(() => {
    if (
      sending === lastQuestionId &&
      question.id === lastQuestionId &&
      isScrollBottom()
    ) {
      goBottom();
    }
  }, [goBottom, sending, isScrollBottom]);

  const onDeleteQuestion = useCallback(
    (questionId: any) => {
      const newQuestions = excludeByIds([questionId])(chat?.currentQuestions);
      const deleteIds = flow(
        uniq,
        compact
      )([...(info?.deleteIds || []), questionId]);
      refreshChat(chatId, {
        currentQuestions: newQuestions,
        info: {
          ...info,
          deleteIds,
        },
      });
      updateChatApi({
        ...info,
        deleteIds,
      });
    },
    [chat?.currentQuestions, info]
  );

  return (
    <div
      className="group w-full text-gray-800"
      key={`question_${question.id}`}
      id={question.id}
    >
      <div className="flex px-4  pt-4 text-base  md:max-w-2xl lg:max-w-[38rem] xl:max-w-3xl   relative">
        <Avatar
          className="w-[32px] h-[32px]"
          shape="square"
          src={getAvatrUrl(user)}
        />

        <div className="flex ml-[10px]  flex-col max-w-[calc(100%-50px)]">
          <div className="text-gray-400 leading-none text-[12px] mb-[8px]">
            {user?.nickname}
          </div>
          <div className="whitespace-pre-wrap group relative bg-[#a9eb7a]  p-[10px]  rounded shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]">
            {question.question || ""}
            <DeleteOutlined
              onClick={() => onDeleteQuestion(question.id)}
              className={` hidden  group-hover:block absolute right-[-30px] top-[-0px] text-gray-400  cursor-pointer`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Question);
