import { Avatar, Button, notification } from "antd";
import { memo, useCallback, useState } from "react";
import { getAvatrUrl } from "../../../../utils/avatar";
import AxiosService from "../../../../api/AxiosService";
import style from "./style.module.scss";
import { useGo } from "../../../../hooks/useGo";
import { useUniverseStore } from "../../../../store/universe";

function Card({
  character,
  refreshChat,
  setOpenEdit,
  setEditCharacter,
}: {
  character: any;
  refreshChat: any;
  setOpenEdit: (key: boolean) => void;
  setEditCharacter: (key: any) => void;
}) {
  const { navigate } = useGo();

  const [notificationCall, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);

  const onUpdate = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setOpenEdit(true);
      setEditCharacter(character);
    },
    [character.id, setOpenEdit]
  );

  const {  updateSchool } = useUniverseStore();

  const onRemove = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setLoading(true);
      AxiosService.post("/api/remove-publish-character", {
        id: character.id,
      })
        .then((res) => {
          if (res) {
            refreshChat(res);
          }
          notificationCall.success({
            message: "下架成功",
          });
          updateSchool(character.school);
        })
        .catch(() => {
          notificationCall.error({
            message: "下架失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [character.id, refreshChat]
  );

  return (
    <div
      key={character.id}
      onClick={() => navigate(`/discover/character/${character.id}`)}
      className="p-[16px] relative cursor-pointer  hover:bg-gray-100 flex group w-[360px]  rounded-xl  "
    >
      {contextHolder}
      <Avatar
        className="w-[90px] h-[90px] rounded-[20px!important] mr-2 "
        shape="square"
        src={getAvatrUrl(character)}
      />
      <div className="flex-1 flex flex-col justify-between ">
        <div className="mb-1 w-full flex ">{character.nickname}</div>
        <div className={`text-sm text-gray-500 ${style.truncate}`}>
          {character.intro}
        </div>
        <div className="flex justify-between p-[16px]   ">
          <Button
            onClick={onUpdate}
            type="primary"
            className="rounded-[20px] px-[20px] py-[4px]"
          >
            更新
          </Button>
          <Button
            loading={loading}
            onClick={onRemove}
            className="rounded-[20px] px-[20px] py-[4px]"
          >
            下架
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(Card);
