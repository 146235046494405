// 聊天记录

import { forwardRef } from "react";

import Model from "../Model/index";
import Content from "../Content/index";

const History = (
  {
    chatId,
    updateTree,
    hasChat,
    systemRole,
    scrollToBottom,
    isScrollBottom,
  }: any,
  wrapRef: any
) => (
  <div
    ref={wrapRef}
    className={`flex  max-w-full flex-1 flex-col overflow-y-auto `}
  >
    <main className="relative h-full w-full transition-width flex flex-col items-stretch flex-1">
      {hasChat ? (
        <Content
          key={`content${chatId}`}
          chatId={chatId}
          updateTree={updateTree}
          systemRole={systemRole}
          goBottom={scrollToBottom}
          isScrollBottom={isScrollBottom}
        />
      ) : (
        <Model chatId={chatId} />
      )}
    </main>
  </div>
);

export default forwardRef(History);
