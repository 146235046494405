import { normalize, map, prop } from "../../../utils/fp";
// mergeAnswer函数接收两个参数，分别为questions和answers
// 这个函数的主要目的是将每个问题与其对应的答案合并
export const mergeAnswer = (questions: any, answers: any) => {
  const answerMap = normalize(answers);
  return map((item: any) => ({
    ...item,
    answer: prop(`${item.id}.answer`)(answerMap) || "",
  }))(questions);
};
