import Markdown from "../../components/Markdown/index";

function Terms() {
  return (
    <div className="flex justify-center p-4">
      <div className="w-[900px]">
        <Markdown>{`# 隐私协议

### 引言

欢迎您使用我们的Chapose网站（“本网站”，“我们”，“本公司”或“服务”）。我们非常重视您的隐私，因此请仔细阅读本隐私政策，以了解我们如何收集、使用、保护或以其他方式处理您的个人信息。

> **注意：** 使用本网站即表示您同意本隐私政策的条款。如果您不同意这些条款，请不要使用本网站。

### 信息收集与使用

#### 类型 of 收集信息

1. **个人标识信息**：如姓名、电子邮件地址、电话号码等。
2. **非个人标识信息**：如使用习惯、访问时间、设备类型等。
3. **技术信息**：如IP地址、浏览器类型、操作系统、访问时间等。

#### 使用信息的目的

1. 提供、维护和改进我们的服务。
2. 进行内部数据分析和研究。
3. 提供客户支持。
4. 发送更新、促销和营销信息。
5. 检测、预防和解决安全问题。



### 信息保护

- 我们采用合适的数据收集、存储和处理措施以及安全措施，以保护您的个人信息不受未授权访问、更改、披露或销毁。
- 使用加密技术保护数据安全。
- 限制员工和合作伙伴访问信息，要求他们遵循严格的信息保密义务。


### 第三方链接与合作

#### 第三方服务提供商

- 我们可能会与第三方服务提供商合作，以更好地运营我们的服务。
- 这些第三方服务提供商可能会根据我们的指示处理您的个人信息。

#### 第三方链接

- 本网站可能包含第三方服务的链接。
- 这些第三方有其自己独立的隐私政策，我们对这些网站和服务的内容和活动不承担任何责任。



### 用户权利

1. **访问权**：您有权访问您的个人信息，并请求更正不准确或不完整的数据。
2. **删除权**：在某些情况下，您有权请求删除您的个人数据。
3. **限制和反对处理**：您有权限制或反对我们处理您的数据。



### 改变隐私政策

- 我们保留随时更新或更改我们的隐私政策的权利。
- 任何更改都会在本页面上发布，并更新“最后更新日期”。



### 联系我们

- 如果您有任何关于本隐私政策的问题，请通过以下方式联系我们：
    - **电子邮件**： contact@chapose.com


---

以上协议最终解释权归成都华度纬度科技有限公司所有，并且保留一切解释和修改的权力。

日期: 2023年9月1日

`}</Markdown>
      </div>
    </div>
  );
}

export default Terms;
