// store.ts
// 引入依赖
import { create } from "zustand";
import type { User } from "../interface/User";
import AxiosService from "../api/AxiosService";

// 定义状态存储类型
export type Store = {
  account: any; // 用户信息
  setAccount: (user: User | null) => void; // 设置用户信息的方法
  getAccount: () => void; // 移除用户信息的方法
};
// 创建状态存储
export const useAccountStore = create<Store>((set) => ({
  account: {}, // 使用本地存储中的用户信息初始化用户信息状态
  setAccount: (account) => set({ account }), // 创建设置用户信息的方法
  getAccount: () => {
    AxiosService.get("/api/get-account").then((res: any) => {
      set({
        account: res,
      });
    });
  }, // 创建移除用户信息的方法
}));
