// 聊天内容展示
import { memo, useEffect, useMemo } from "react";
import { Avatar } from "antd";
import { prop, replaceN, isEmpty } from "../../../../utils/fp";

import Markdown from "../../../../components/Markdown";
import styles from "./style.module.scss";
import CharacterCard from "../../../../components/Character/Card";
import { getAvatrUrl } from "../../../../utils/avatar";
import { useChatStore } from "../../store/index";

const Answer = ({ chatId, goBottom, systemRole, question, lastQuestionId }: any) => {
  const { chatMap, refreshChat } = useChatStore();

  const sending = useMemo(() => prop(`${chatId}.sending`)(chatMap), [chatId, chatMap]);

  useEffect(() => {
    if (sending === false && question.id === lastQuestionId) {
      goBottom();
      refreshChat(chatId, {
        sending: null,
      });
    }
  }, [goBottom, lastQuestionId, question, sending ]);

  return (
    <div className="flex relative p-4 group  text-base md:max-w-2xl lg:max-w-[38rem] xl:max-w-3xl md:py-6   ">
      <div>
        <CharacterCard id={systemRole?.id}>
          <Avatar
            src={getAvatrUrl(systemRole)}
            className="w-[32px] h-[32px]"
            shape="square"
          />
        </CharacterCard>
      </div>
      <div className="relative ml-[10px]  flex max-w-[calc(100%-50px)] flex-col">
        <div className="text-gray-400 leading-none  text-[12px] mb-[8px]">
          <CharacterCard id={systemRole?.id}>
            {systemRole?.nickname}
          </CharacterCard>
        </div>

        <div className="bg-white p-[10px]  rounded  shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]">
          {sending &&
            sending === question.id &&
            isEmpty(question.answer) && <span className={`${styles.loader}`} />}
          <Markdown cursor={sending === question.id}>
            {replaceN(question.answer || "")}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

export default memo(Answer);
