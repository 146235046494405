import { useTranslation } from "react-i18next";
import "./App.css";
import { ConfigProvider, Spin } from "antd";
import { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter , useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { includes, isEmpty } from "./utils/fp";
import Layout from "./components/layout";

import Profile from "./views/Profile";
import MyCharacter from "./views/Contects/Character"; 
import "moment/locale/zh-cn";
import theme from "./constants/theme";
import "./i18n/config";
import NoMatch from "./views/NoMatch/NoMatch";
 
import Sign from "./views/Login/Sign";
import Chat from "./views/Chat/index";
import Terms from "./views/Legal/Terms";
import Privacy from "./views/Legal/Privacy";
import { useDocumentTitle } from "./hooks/useDocumentTitle";
import LocalStorageService from "./service/LocalStorageService";
import { useGetMeta } from "./hooks/useGetMeta";
import type { UserStore } from "./store/user";
import { useUserStore } from "./store/user";
import ChatLayout from "./views/Chat/Layout";
import AxiosService from "./api/AxiosService";
import Discover from "./views/Discover/index";
import Publish from "./views/Discover/Publish";
import Character from "./views/Discover/Character";
import Recharge from "./views/Profile/Recharge";

//  如果是白名单中的路由，就不需要登录，否则就需要登录
const whiteList = ["/login", "/sign", "/terms", "/privacy"];
 

function App() {
  const { t, i18n } = useTranslation();

  const [isAuthorized, setIsAuthorized] = useState(false);

  const { setUser, currentUser } = useUserStore((state: UserStore) => ({
    currentUser: state.user,
    setUser: state.setUser,
  }));

  useEffect(() => {
    const lang = LocalStorageService.getItem("lang") || "zh";
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [i18n]);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const { getMeta } = useGetMeta();

  useEffect(() => {
    if (isAuthorized) {
      return;
    }
    const isLogin =  includes(currentPath)(whiteList);
    if (!isLogin && isEmpty(currentUser)) {
      AxiosService.get("/api/get-profile")
        .then((res: any) => {
          if (res) {
            setUser(res);
            getMeta();
            if (currentPath === "/") {
              navigate("/chat");
            }
          } else {
            navigate(`/sign?redirect=${  currentPath}`);
          }
        })
        .catch(() => {
          navigate(`/sign?redirect=${  currentPath}`);
        })
        .finally(() => {
          setIsAuthorized(true);
        });
    } else {
      if (currentPath === "/") {
        navigate("/chat");
      }
      setIsAuthorized(true);
      getMeta();
    }
  }, [setUser, navigate, currentPath, isAuthorized, currentUser, getMeta]);

  useDocumentTitle(t("title"));

  return (
    <div id="app" className="App">
      <ConfigProvider theme={theme}>
        {isAuthorized ? (
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="chat" element={<ChatLayout />}>
                <Route index path=":id" element={<Chat />} />
                <Route index element={<Chat />} />
              </Route>
              <Route path="profile" element={<Profile />} />
              <Route path="profile/recharge" element={<Recharge />} />
              <Route path="contacts" element={<MyCharacter />} />


              <Route path="discover" element={<Discover />} />
              <Route path="discover/publish" element={<Publish />} />
              <Route path="discover/character/:id" element={<Character />} />
            </Route>
 
            <Route path="sign" element={<Sign />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        ) : (
          <div className=" fixed left-0 right-0 top-0 bottom-0  bg-black  opacity-80    flex  justify-center items-center z-10  ">
              <Spin size="large" className="text-center " />
              <div className="ml-4 font-[20px] font-bold text-[#07c160] ">
                {" "}
                Signing ...
              </div>
            </div>
        )}
      </ConfigProvider>
    </div>
  );
}

export default function Provider() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
