import { useRef, useEffect, useState } from "react";

export const useScrollBottom = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const [onBottom, setBottom] = useState(false);
  const [userScroll, setUserScroll] = useState(false);
  // 是否到达底部
  const isBottom = () => {
    const element = wrapRef.current;
    if (element) {
      return (
        wrapRef.current &&
        wrapRef.current.scrollTop + wrapRef.current.clientHeight >=
          wrapRef.current.scrollHeight
      );
    }
  };
 
  
  const isScrollBottom = () => onBottom && !userScroll;
  // 滚动到底部
  const scrollToBottom = () => {
    if (wrapRef.current) {
     // setUserScroll(false);
      setBottom(true);
      wrapRef.current.scrollTop = wrapRef.current.scrollHeight;
    }
  };
  // 检测用户进行滑动操作，如果用户滑动则更改标志位
  useEffect(() => {
    const handleScroll = () => {
      setUserScroll(!isBottom());
    };

    wrapRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      wrapRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // 如果已经到达底部，并且这次滚动是用户触发的，再次滚动到底部
    if (isBottom() && userScroll) {
      scrollToBottom();
    }
  });
  return { wrapRef, isBottom, scrollToBottom, setBottom, isScrollBottom };
};
