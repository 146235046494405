// JavaScript中的import语句
import { filter, includes } from "../../utils/fp";
/**
 * Excludes nodes with given ids.
 * 排除给定id的节点。
 *
 * @param {Array} ids - An array of ids to be excluded.
 * @param {Array} data - An array of nodes.
 *
 * @returns {Array} An array of nodes that do not have the ids specified.
 */
export const excludeByIds = (ids: string[]) => (data: any[]) =>
  filter((item:any) => !includes(item.id)(ids))(data);
/**
 * Extracts usernames from a given string that follow the "@" character.
 * 从给定字符串中提取"@"后面的用户名。
 *
 * @param {string} text - A string likely containing usernames following the "@" character.
 *
 * @returns {Array<string>} - An array of usernames found in the text.
 */
export function extractUsernames(text:string) {
  const regex = /@(\S+)/gi;
  const match = text.match(regex);
  return match || [];
}
