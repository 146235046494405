import { useLocation } from "react-router-dom";
import { useCharacterStore } from "../../../store/character";

type UseCharacterIdReturn = {
  characterId: string;
  searchParams: URLSearchParams;
};
export const useCharacterId = (): UseCharacterIdReturn => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { getDefaultRole } = useCharacterStore();
  const characterId = searchParams.get("character") || getDefaultRole();
  // 现在我们返回所有的 `searchParams`，不仅仅是 `characterId`。
  return { characterId, searchParams };
};