import { memo, useCallback } from "react";
import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Create from "../../../components/Character/Create";
import SetDefault from "./SetDefault";
import Remove from "./Remove";
import { DEFAULT_ROLE } from "../../../constants/universe";

interface Props {
  active: any;
  setActive: (active: any) => void;
}

const More = ({ active, setActive }: Props) => {
  const onEditSuccess = useCallback(
    (res: any) => {
      setActive(res);
    },
    [setActive]
  );

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Create key={active.id} character={active} setRole={onEditSuccess}>
          编辑
        </Create>
      ),
    },
    {
      key: "2",
      label: (
        <Create
          key={`${active.id}+copy`}
          character={active}
          setRole={onEditSuccess}
          isCopy
        >
          复制
        </Create>
      ),
    },
    {
      key: "3",
      disabled: active.isDefault,
      label: <SetDefault active={active} setActive={setActive} />,
    },
    {
      key: "4",
      disabled: active.id === DEFAULT_ROLE,
      danger: true,
      label: <Remove active={active} setActive={setActive} />,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" />
    </Dropdown>
  );
};

export default memo(More);
