// 引入库和一些其他需要的文件
import { create } from "zustand";
import AxiosService from "../../../api/AxiosService";
import { getTokenSize } from "../../../utils/tokenSize";
import { updateChatApi } from "../../../api/chat";
import ChatDatabase from "../../../service/ChatDatabase";
import { CHAT_INIT } from "./constant";
import { prop, last, noEmpty, forEach } from "../../../utils/fp";
import { mergeAnswer } from "./utils";

export const computedSize = (messages: []) => {
  let all = "";
  forEach((item: any) => {
    if (item.content) {
      all += item.content;
    }
  })(messages);

  return getTokenSize(all);
};

// 初始化数据库实例
const db = new ChatDatabase();
// 定义chat store的数据类型
export type RFState = {
  chatMap: any;
  addQuestion: (questions: any, context: any) => any;
  getChat: (id: string | null) => any;
  newChat: (meta: any) => any;
  initChat: (id: string, meta?: any) => any;
  refreshChat: (id: string, meta?: any) => any;
};
// 创建chat store
export const useChatStore = create<RFState>((set, get) => ({
  chatMap: {},
  initChat: (id: string, meta: any) => {
    set({
      chatMap: {
        ...get().chatMap,
        [id]: {
          ...CHAT_INIT,
          ...(meta || {}),
        },
      },
    });
  },
  refreshChat: (id: string, meta: any) => {
    const oldChat = prop(`chatMap.${id}`)(get()) || {};
    set({
      chatMap: {
        ...get().chatMap,
        [id]: {
          ...oldChat,
          ...(meta || {}),
        },
      },
    });
    if (meta?.currentQuestions) {
      db.upsert({
        ...oldChat,
        ...meta,
      });
    }
    // console.log("refreshChat", id, meta);
  },
  // 添加节点的方法，包括使用TreeBuilder构建树形结构，然后更新store中的数据。同时对question和answer进行提交到后端
  addQuestion: (questions: any, context: any) => {
    const question = last(questions);
    const id = question?.chatId;
    const oldChat = prop(`chatMap.${id}`)(get()) || {};
    const newChat = {
      ...oldChat,
      id,
      questions,
    };
    set({
      chatMap: {
        ...get().chatMap,
        [id]: {
          ...oldChat,
          ...newChat,
          ...(newChat?.info || {}),
          currentQuestions: questions,
        },
      },
    });
    db.upsert(newChat);
    const inputToken = computedSize(context);
    // 提交请求
    AxiosService.post("/api/ask-question", {
      ...question,
      inputToken,
      outputToken: getTokenSize(question.answer),
    });
    // 更新store中的chat数据
    return updateChatApi({ ...newChat?.info, lastMessage: question?.question });
  },
  // 获取Chat数据，先从数据库中找，没有再从后端API中获取并保存到数据库
  getChat: (id: string | null) => {
    if (!id) return;
    const oldChat = prop(`chatMap.${id}`)(get());
    db.get(id).then((res: any) => {
      if (res) {
        set({
          chatMap: {
            ...get().chatMap,
            [id]: {
              ...oldChat,
              ...res,
              ...(res?.info || {}),
              currentQuestions: res?.questions,
            },
          },
        });
      }
    });
    return AxiosService.get(`/api/get-chat?id=${id}`).then((res: any) => {
      if (res?.error) return;
      if (noEmpty(res?.questions)) {
        const questions = mergeAnswer(res?.questions, res?.answers);
        const chat = {
          ...res,
          id,
          questions,
        };
        set({
          chatMap: {
            ...get().chatMap,
            [id]: {
              ...oldChat,
              ...chat,
              ...(chat?.info || {}),
              currentQuestions: questions,
            },
          },
        });
        db.upsert(chat);
        AxiosService.post("/api/use-character", {
          id: res?.info?.role,
        });
      }

      return res;
    });
  },
  // 创建新的chat并保存到store和数据库中
  newChat: (values: any) => {
    set({
      chatMap: {
        ...get().chatMap,
        [values?.id]: {
          info: values,
          ...values,
        },
      },
    });
    return AxiosService.post("/api/new-chat", values);
  },
}));
