import { useState, useRef, useCallback } from "react";

interface Result<T> {
  data: T | null;
  error: Error | null;
}

export function usePolling<T>(
  fetchData: (id: string) => Promise<T>,
  delay: number,
  callback: () => void
): {
  result: Result<T>;
  stopPolling: () => void;
  startPolling: (id: string) => void;
} {
  const [result, setResult] = useState<Result<T>>({ data: null, error: null });
  const intervalRef = useRef<number | null>(null);

  const stopPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const startPolling = useCallback(
    (id: string) => {
      stopPolling();
      intervalRef.current = window.setInterval(async () => {
        try {
          const data: any = await fetchData(id);
          setResult({ data, error: null });
          if (data && data?.paied) {
            callback();
            stopPolling();
          }
        } catch (error: any) {
          setResult({ data: null, error });
        }
        //  stopPolling();
      }, delay);
    },
    [fetchData, delay, stopPolling]
  );

  return {
    result,
    stopPolling,
    startPolling,
  };
}
