import { Button, Spin } from "antd";
import { useCallback } from "react";
import { useUniverseStore } from "../../store/universe";
import School from "./components/School/index";
import { useGo } from "../../hooks/useGo";
import { map, prop, noEmpty } from "../../utils/fp";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

function Discover() {
  const { navigate } = useGo();

  const { schools, schoolMap } = useUniverseStore();

  const onGo = useCallback(
    (id: any) => () => {
      document?.getElementById(id)?.scrollIntoView();
    },
    []
  );

  useDocumentTitle("发现有趣的人");

  return (
    <div className="w-full bg-gray-100 h-full p-[20px] overflow-y-auto ">
      <div className="flex mb-4 items-center  justify-between ">
        <div>
          <div className="text-gray-500 m1-4 ">发现有趣的人</div>
        </div>
        <Button
          size="large"
          type="primary"
          onClick={() => navigate("/discover/publish")}
          className="bg-white"
        >
          发布
        </Button>
      </div>
      <div className="flex mb-4">
        {map(
          (school: any) =>
            noEmpty(prop(`${school?.id}.characters`)(schoolMap)) && (
              <div
                className="bg-white mr-2 mb-2 text-gray-600 text-[14px]  px-3 py-1 rounded-[20px] cursor-pointer"
                key={school?.id}
                onClick={onGo(school?.id)}
              >
                {school?.name}
              </div>
            )
        )(schools)}
      </div>
      {noEmpty(schools) ? (
        map(
          (school: any) =>
            noEmpty(prop(`${school?.id}.characters`)(schoolMap)) && (
              <School
                characters={prop(`${school?.id}.characters`)(schoolMap)}
                name={school?.name}
                key={school?.id}
                id={school?.id}
              />
            )
        )(schools)
      ) : (
        <div className="w-full h-full     flex  justify-center items-center   ">
          <Spin size="large" className="text-center " />
          <div className="ml-4 font-[20px] font-bold text-[#07c160] ">
            {" "}
            Loading ...
          </div>
        </div>
      )}
    </div>
  );
}

export default Discover;
