import { memo, useMemo, useState, useCallback } from "react";
import { Avatar, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { find, map, sortBy, filter, strArrayToMap, has } from "../../utils/fp";
import CreateCharacter from "./Create";
import { useCharacterStore } from "../../store/character";
import CharacterCard from "./Card";
import { getAvatrUrl } from "../../utils/avatar";

type Props = {
  role: any;
  setRole: (key: string) => void;
  exclude?: string[];
};

const Role = ({ role, setRole, exclude = [] }: Props) => {


  const [isOpen, setIsOpen] = useState(false);

  const { character } = useCharacterStore((state) => ({
    character: state.character,
  }));

  const characterList = useMemo(() => {
    const excludeMap = strArrayToMap(exclude);

    return filter((item: any) => !has(item.id)(excludeMap))(character);
  }, [character, exclude]);

  const roleConfig = useMemo(
    () => find((item: any) => item.id === role)(characterList),
    [role, characterList]
  );

  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const Content = useMemo(() => {
    const list = sortBy((item: any) => item.id !== role)(characterList);
    return (
      <div>
        <div className="w-[300px] h-[300px] overflow-y-auto">
          {map((item: any) => (
            <div
              onClick={() => {
                setRole(item.id);
                hide();
              }}
              key={item.id}
              className="flex border-b border-gray-100 px-4  items-center py-2 cursor-pointer"
            >
              <CharacterCard key={item.id} id={item.id}>
                <Avatar
                  src={getAvatrUrl(item)}
                  shape="square"
                  className="mr-2 w-[36px] h-[36px]"
                />
              </CharacterCard>
              <div>
                <div className="">{item?.nickname}</div>
              </div>
            </div>
          ))(list)}
        </div>
        <div className="p-4 bg-gray-100">
          <CreateCharacter setRole={setRole} callback={hide} />
        </div>
      </div>
    );
  }, [characterList, setRole, hide, role]);

  return (
    <div className="flex">
      <Popover
        placement="bottom"
        content={Content}
        trigger="click"
        open={isOpen}
        overlayInnerStyle={{ padding: 0, overflow: "hidden" }}
      >
        <div className="flex   items-center p-2 rounded-xl bg-gray-100 ">
          <div
            className="cursor-pointer bg-white py-3 px-4 shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)] rounded-xl"
            onClick={toggle}
          >
            {roleConfig ? (
              <>
                <Avatar
                  shape="square"
                  src={getAvatrUrl(roleConfig)}
                  className="mr-1"
                />
                <span>{roleConfig?.nickname}</span>
                <DownOutlined className="ml-1 text-gray-400 w-[10px] " />
              </>
            ) : (
              <>
                选择角色
                <DownOutlined className="ml-1 text-gray-400 w-[10px] " />
              </>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default memo(Role);
