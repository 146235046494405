/* 一个展示用户信息的卡片组件 */
import { memo, useCallback } from "react";
import { Avatar, Popover } from "antd";
import { useCharacterStore } from "../../store/character";
import CreateCharacter from "./Create";
import { isFunction } from "../../utils/fp";
import { getAvatrUrl } from "../../utils/avatar";

function CharacterCard({
  id,
  children,
  placement = "bottomLeft",
  callback,
  mouseEnterDelay = 0.6,
}: {
  id: string;
  children: any;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  callback?: () => void;
  mouseEnterDelay?: number;
}) {
  const { characterMap } = useCharacterStore();

  const hide = useCallback(() => {
    if (callback && isFunction(callback)) {
      callback();
    }
  }, [callback]);

  const character = characterMap[id] || {};

  return (
    <Popover
      placement={placement}
      trigger="hover"
      mouseEnterDelay={mouseEnterDelay}
      overlayInnerStyle={{ padding: 0, overflow: "hidden" }}
      content={
        <div className="w-[600px]">
          <div className="flex flex-col h-[120px] bg-gray-100 items-center justify-center relative  ">
            <Avatar shape="square" className="w-[60px]  h-[60px]" src={getAvatrUrl(character)} />
            <div className="mt-[10px]">{character.nickname}</div>
            <div className="absolute right-5 top-5">
              <CreateCharacter character={character} callback={hide} />
            </div>
          </div>

          <div className="flex p-[20px] ">
            <div className="w-[40px] text-gray-500">介绍</div>{" "}
            <div className="w-[520px] whitespace-pre-wrap max-h-[300px]  overflow-y-auto">
              {character.intro || "-"} 
            </div>
          </div>
          <div className="flex px-[20px] pb-[20px]">
            <div className="w-[40px] text-gray-500">设定</div>
            <div className="w-[520px] whitespace-pre-wrap max-h-[300px]   overflow-y-auto ">
               {character.description} 
            </div>
          </div>
        </div>
      }
      arrow={false}
    >
      <div className="hover:opacity-80 cursor-pointer inline-block">{children}</div>
    </Popover>
  );
}

export default memo(CharacterCard);
