// 聊天内容展示
import { memo, useMemo } from "react";
import { map, prop, last } from "../../../../utils/fp";
import Question from "./Question";
import Answer from "./Answer";

import { useChatStore } from "../../store/index";

const Content = ({ chatId, goBottom, systemRole, isScrollBottom }: any) => {
  const { chatMap } = useChatStore();

  const currentQuestions = useMemo(() => prop(`${chatId}.currentQuestions`)(chatMap), [chatId, chatMap]);
  const lastQuestionId = useMemo(() => prop('id')(last(currentQuestions)), [currentQuestions]);


  return (
    <div className="flex-1 bg-[#f3f3f3]  ">
      <Answer
        key={`welcome_${systemRole?.id}`}
        question={{ id: "welcome", answer: systemRole?.intro }}
        goBottom={goBottom}
        systemRole={systemRole}
        chatId={chatId}
      />

      {map((item: any) => (
        <div key={item.id}>
          <Question
            key={`question_${item.id}`}
            question={item}
            chatId={chatId}
            goBottom={goBottom}
            isScrollBottom={isScrollBottom}
            lastQuestionId={lastQuestionId}
          />
          <Answer
            key={`answer_${item.id}`}
            question={item}
            goBottom={goBottom}
            chatId={chatId}
            systemRole={systemRole}
            lastQuestionId={lastQuestionId}
          />
        </div>
      ))(currentQuestions)}
    </div>
  );
};

export default memo(Content);
