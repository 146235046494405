import { useCallback, memo, useMemo } from "react";

import { useParams } from "react-router-dom";
import { Avatar } from "antd";
import { map, prop, filter } from "../../../../utils/fp";
import { useGo } from "../../../../hooks/useGo";
import styles from "./index.module.scss";
import { getAvatrUrl } from "../../../../utils/avatar";
import { useCharacterStore } from "../../../../store/character";

interface RepoProps {
  list: any[];
}

const Repo = ({ list }: RepoProps) => {
  const { navigate } = useGo();

  const onGo = useCallback(
    (id: string) => () => {
      navigate(`/chat/${id}`);
    },
    [navigate],
  );

  const { id } = useParams();

  const { characterMap } = useCharacterStore();

  const chatList = useMemo(() => filter((item: any) => prop(`${item.role}`)(characterMap))(list), [list, characterMap]);

  return (
    <div className="flex-col flex-1 transition-opacity duration-500 overflow-y-auto ">
      <div className="flex flex-col gap-2 pb-2 text-gray-100 text-sm">
        <div>
          <div className={`relative ${styles.wrap}`}>
            {map((item: any) => (
              <div
                className={`${styles.item} group ${
                  item.id === id ? "bg-gray-100" : "bg-white"
                }  w-full relative   text-gray-800 px-4 py-3  hover:bg-gray-50   transition-all  cursor-pointer`}
                key={`${item.id}intro`}
                onClick={onGo(item.id)}
              >
                <div className="flex">
                  <Avatar
                    shape="square"
                    src={getAvatrUrl(prop(`${item.role}`)(characterMap))}
                    className="mr-2 w-[40px] h-[40px]"
                  />
                  <div>
                    <div>
                      <div className="font-medium mb-[4px]">
                        {prop(`${item.role}.nickname`)(characterMap)}
                      </div>
                      <div className={`${styles.title} text-gray-500 text-xs`}>
                        {item.lastMessage || item.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))(chatList)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Repo);
