import { memo, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Avatar } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { map, filter, strArrayToMap, has } from "../../utils/fp";

import { useCharacterStore } from "../../store/character";

import { getAvatrUrl } from "../../utils/avatar";

type Props = {
  role: any;
  setRole: (key: string) => void;
  exclude?: string[];
};

const Role = ({ role, setRole, exclude = [] }: Props) => {
  const location = useLocation();

  const { character } = useCharacterStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const characterId = searchParams.get("character");

    if (characterId) {
      setRole(characterId);
    }
  }, [location, setRole]);

  const characterList = useMemo(() => {
    const excludeMap = strArrayToMap(exclude);

    return filter((item: any) => !has(item.id)(excludeMap))(character);
  }, [character, exclude]);

  const Content = useMemo(
    () => (
      <div>
        <div className="w-full h-[300px] overflow-y-auto">
          {map((item: any) => (
            <div
              onClick={() => {
                setRole(item.id);
              }}
              key={item.id}
              className="flex border-b border-gray-100 px-4  items-center py-2 cursor-pointer"
            >
              <div className="w-[40px] h-full">
                {item.id === role && (
                  <CheckCircleOutlined className="text-[20px] text-[#07C160]" />
                )}
              </div>

              <Avatar
                src={getAvatrUrl(item)}
                shape="square"
                className="mr-1 w-[36px] h-[36px]"
              />

              <div>
                <div className="">{item?.nickname}</div>

                <div className="text-gray-500 text-xs w-[530px] overflow-hidden overflow-ellipsis whitespace-nowrap  ">
                  {item?.intro}
                </div>
              </div>
            </div>
          ))(characterList)}
        </div>
      </div>
    ),
    [characterList, setRole, role]
  );

  return <div className="flex">{Content}</div>;
};

export default memo(Role);
