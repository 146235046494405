/* 一个展示用户信息的卡片组件 */
import { memo, useState, useEffect } from "react";
import { Empty } from "antd";
import AxiosService from "../../api/AxiosService";
import { useCharacterId } from "./hooks/useCharacterId";
import { isEmpty } from "../../utils/fp";
import Cover from "./components/Cover/index";
import { useUniverseStore } from "../../store/universe";

function Character() {
  const [character, setCharacter]: any = useState({});
  const [loaded, setLoaded] = useState(false);
  const { characterId } = useCharacterId();
  const { getCharacter } = useUniverseStore();

  useEffect(() => {
    setCharacter(getCharacter(characterId) || {});
    AxiosService.get(`/api/get-character?id=${characterId}`)
      .then((res: any) => {
        setCharacter(res);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [characterId]);

  return isEmpty(character) && loaded ? (
    <Empty className="mt-20" description="没有找到角色" />
  ) : (
    <div className="h-full overflow-y-auto ">
      <Cover character={character} />
      <div className="max-w-[800px] m-auto">
        <div className="flex p-[20px] ">
          <div className="w-[60px]  text-gray-500">介绍</div>{" "}
          <div className="flex-1 whitespace-pre-wrap">
            {character?.intro || "-"}
          </div>
        </div>
        <div className="flex px-[20px] pb-[20px]">
          <div className="w-[60px]  text-gray-500">设定</div>
          <div className="flex-1 whitespace-pre-wrap">
            {character?.description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Character);
