import { Avatar } from "antd";

import { useUserStore } from "../../../store/user";
import { getAvatrUrl } from "../../../utils/avatar";

const Profile = ({ children }: any) => {
  const { user } = useUserStore();

  return (
    <div className="rounded-lg flex p-6 bg-gray-100">
      <Avatar
        shape="square"
        className="mr-2 w-[52px] h-[52px] "
        src={getAvatrUrl(user)}
        alt={user?.nickname}
      />
      <div>
        {user?.nickname}
        <div className="text-sm text-gray-500">
          账户余额{" "}
          <span className="text-rose-500  text-[20px]">
            {user?.amount || 0}
          </span>
          豆
          {children}
        </div>
      </div>
    </div>
  );
};

export default Profile;
