import { memo, useState, useCallback } from "react";
import { Modal, Form, Button, notification, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useUniverseStore } from "../../../../store/universe";
import { map, isFunction, prop } from "../../../../utils/fp";
import AxiosService from "../../../../api/AxiosService";
import CharacterRadio from "../../../../components/Character/Radio";
import { useCharacterStore } from "../../../../store/character";
 

type Props = {
  callback?: () => void;

  exclude?: string[];
};

const Role = ({ callback, exclude }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { schools, updateSchool } = useUniverseStore();

  const [role, setCharacter] = useState(false);

  const [form] = Form.useForm();

  const [notificationCall, contextHolder] = notification.useNotification();

  const options = map((item: any) => ({
    label: item.name,
    value: item.id,
  }))(schools);

  const showModal = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const setRole = useCallback((key: any) => {
    setCharacter(key);
    form.setFieldsValue({
      role: key,
    });
  }, []);

  const { characterMap } = useCharacterStore();

  const onFinish = useCallback(
    (values: any) => {
      const character = prop(role)(characterMap);
      const meta = {
        ...character,
        ...values,
      };
      setLoading(true);
      AxiosService.post("/api/publish", meta)
        .then(() => {
          setIsModalOpen(false);
          notificationCall.success({
            message: "角色发布成功",
          });
          updateSchool(values.school);
          if (callback && isFunction(callback)) {
            callback();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [role, characterMap, callback, notificationCall]
  );

  return (
    <div>
      {contextHolder}

      <Button
        onClick={showModal}
        type="primary"
        size="large"
        icon={<PlusCircleOutlined />}
      >
        新角色
      </Button>

      <Modal
        title="发布新角色"
        open={isModalOpen}
        footer={null}
        width={800}
        onCancel={hideModal}
        forceRender
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="角色"
            name="role"
            rules={[{ required: true, message: "请选择角色!" }]}
          >
            <CharacterRadio role={role} setRole={setRole} exclude={exclude} />
          </Form.Item>

          <Form.Item
            label="分类"
            name="school"
            rules={[{ required: true, message: "请选择分类!" }]}
          >
            <Select style={{ width: 120 }} options={options} />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            发布
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Role);
