// 获取元数据
import { useCallback } from "react";
import { useUniverseStore } from "../store/universe";
import { useCharacterStore } from "../store/character";
import { useAccountStore } from "../store/account";

export const useGetMeta = () => {
  const { getUniverse } = useUniverseStore();

  const { getMyCharacter } = useCharacterStore();
  const { getAccount } = useAccountStore();

  const getMeta = useCallback(() => {
    getUniverse();
    getMyCharacter();
    getAccount();
  }, [getUniverse, getMyCharacter]);

  return {
    getMeta,
  };
};
