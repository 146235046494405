import React from "react";

interface Props {
  amount: number;
  currency?: string;
}

const CurrencyDisplay = ({ amount, currency = "CNY" }: Props) => {
  const formatter = new Intl.NumberFormat("zh-CN", {
    style: "currency",
    currency,
  });
  return <span>{formatter.format(amount)}</span>;
};

export default CurrencyDisplay;
