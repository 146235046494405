import { useRef, useEffect } from 'react';
// 使用 AbortController 的自定义 Hook
export const useController = () => {
    // useRef 创建一个可变的 ref 对象，其 .current 属性被初始化为传递的参数
    // 在这里用来保存 AbortController 实例
    const controllerRef = useRef<AbortController | null>(null);
    useEffect(() => {
      // 创建 AbortController 实例，只在组件的生命周期的第一阶段（componentDidMount）执行一次
      controllerRef.current = new AbortController();
      return () => {
        // 在组件卸载时（componentWillUnmount）取消未完成的请求
        controllerRef.current?.abort();
      };
      // 空数组意味着 useEffect 将在组件加载和卸载时执行一次
    }, []);
    const initContoller = () => {
        controllerRef.current = new AbortController();
    }
    return {
        controller: controllerRef.current,
        initContoller,
    }
}