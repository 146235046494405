import { memo } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Left from "./Left";
import Right from "./Right";
import { map, noEmpty } from "../../../../utils/fp";
import Card from "./Card";
import "react-horizontal-scrolling-menu/dist/styles.css";
 
function School({
  characters,
  name,
  id,
}: {
  characters: any[];
  name?: string;
  id?: string;
}) {
  return (
    <div
      key={id}
      id={id}
      className="w-full max-w-full bg-white  p-[20px] relative mb-[20px] rounded-xl "
    >
      <div className="mb-4 px-[20px] font-medium ">{name}</div>
      <div className="w-[calc(100vw-160px)] relative">
        <ScrollMenu
          LeftArrow={noEmpty(characters) && Left}
          RightArrow={noEmpty(characters) && Right}
        >
          {map((character: any) => (
            <Card key={character.id} character={character} />
          ))(characters)}
        </ScrollMenu>
      </div>
    </div>
  );
}

export default memo(School);
