import { Button, notification } from "antd";
import { memo, useCallback } from "react";
import { useGo } from "../../../../hooks/useGo";
import { useNewCharacter } from "../../hooks/useNewCharacter";
import { useCharacterStore } from "../../../../store/character";
import { prop } from "../../../../utils/fp";


function ChatButton({ character }: { character: any }) {
  const { navigate } = useGo();

  const [notificationCall, contextHolder] = notification.useNotification();

  const { loading, onNewCharacter } = useNewCharacter();
  const { characterMap } = useCharacterStore();

  const goChat = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const source = prop("source")(character);
      if (characterMap[source]) {
        return navigate(`/chat/?character=${source}`);
      }

      onNewCharacter(character)
        .then((res: any) => {
          navigate(`/chat/?character=${res?.id}`);
        })
        .catch(() => {
          notificationCall.error({
            message: "开始聊天失败",
          });
        });
    },
    [navigate, character.id]
  );

  return (
    <>
      {contextHolder}
      <Button
        loading={loading}
        onClick={goChat}
        type="primary"
        className="rounded-[20px] px-[20px] text-sm py-[4px]"
      >
        聊天
      </Button>
    </>
  );
}

export default memo(ChatButton);
