import React, { useCallback } from "react";
import {
  CommentOutlined,
  CompassOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { useLocation } from "react-router-dom";
import { includes } from "../../utils/fp";
import { useGo } from "../../hooks/useGo";
import { useUserStore } from "../../store/user";
import { getAvatrUrl } from "../../utils/avatar";

const Bar = () => {
  const { navigate } = useGo();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = useUserStore();

  const go = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate],
  );

  const getBrandClass = useCallback(
    (path: string) => {
      if (includes(path)(currentPath)) {
        return "text-brand-green";
      }
      return "";
    },
    [currentPath],
  );

  const getBgClass = useCallback(
    (path: string) => {
      if (includes(path)(currentPath)) {
        return " bg-gray-900 ";
      }
      return "";
    },
    [currentPath],
  );

  return (
    <div className="flex  flex-col justify-between  w-[80px] text-white  z-10 bg-gray-700  px-[6px]  py-[20px]  h-screen">
      <div>
        <div
          onClick={go("/chat")}
          className={`${getBgClass(
            "/chat",
          )} flex flex-col items-center py-[14px] rounded-lg cursor-pointer mb-4`}
        >
          <CommentOutlined
            className={`${getBrandClass("/chat")} text-[22px]`}
          />
          <div className="mt-[8px] text-[12px] ">聊天</div>
        </div>

        <div
          onClick={go("/contacts")}
          className={`${getBgClass(
            "/contacts",
          )} flex flex-col items-center py-[14px] rounded-lg cursor-pointer mb-4`}
        >
          <UserOutlined 
            className={`${getBrandClass("/contacts")} text-[22px]`}
          />
          <div className="mt-[8px] text-[12px] ">角色</div>
        </div>
     
        <div
          onClick={go("/discover")}
          className={`${getBgClass(
            "/discover",
          )} flex flex-col items-center p-[10px] rounded-lg cursor-pointer mb-2`}
        >
          <CompassOutlined
            className={`${getBrandClass("/discover")} text-[22px]`}
          />
          <div className="mt-[10px] text-[12px] ">发现</div>
        </div>
       
      </div>
      <div>
     
        <div
          onClick={go("/profile")}
          className={`${getBgClass(
            "/profile",
          )} flex flex-col items-center p-[10px] rounded-lg cursor-pointer`}
        >
           <Avatar
            shape="square"
            className=" w-[48px] h-[48px]"
            src={getAvatrUrl(user)}
          />
          
        </div>
      </div>
    </div>
  );
};

export default Bar;
