import React, { useState, useCallback, useMemo } from "react";
import { notification, Input, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import AxiosService from "../../api/AxiosService";
import { prop, trim } from "../../utils/fp";
import { validatePhoneNumber, validateCode } from "./utils";
import styles from "./style.module.scss";
import { useSetUserInfo } from "./hooks/useSetUserInfo";
import Init from "../../components/Profile/Init";
import { useGo } from "../../hooks/useGo";
import Timer from "./components/Timer";
import Copyright from "./components/Footer";

const Sign: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const [captcha, setCaptcha] = useState<any>("");
  const [captchaRes, setCaptchaRes] = useState<any>({});
  const [captchaed, setCaptchaed] = useState<any>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const { setUserInfo } = useSetUserInfo();
  const { navigate } = useGo();

  const onSendCaptcha = useCallback(
    (res: any) => {
      if (!res?.ticket) {
        notificationCall.error({
          message: "请先完成验证",
        });
        setLoading(false);
        return;
      }
      AxiosService.post("/api/send-captcha", {
        phone: `+86${phone}`,
        t: res?.ticket,
        r: res?.randstr,
      })
        .then(() => {
          setCaptchaed(true);
        })
        .catch((err) => {
          notificationCall.error({
            message: prop("error")(err),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [notificationCall, phone],
  );

  const onValidateHuman = useCallback(() => {
    if (!validatePhoneNumber(phone)) {
      notificationCall.error({
        message: "请输入正确的手机号",
      });
      return;
    }
    setLoading(true);

    function callback(res: any) {
      // 第一个参数传入回调结果，结果如下：
      // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
      // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
      // CaptchaAppId       String    验证码应用ID。
      // bizState    Any       自定义透传参数。
      // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
   
      onSendCaptcha(res);
      setCaptchaRes(res);
    }
    // @ts-ignore
    const tencentCaptcha = new TencentCaptcha("191505718", callback, {});
    // 调用方法，显示验证码
    tencentCaptcha.show();
  }, [phone, notificationCall, setCaptchaRes, onSendCaptcha]);

  const onChangePhone = useCallback((e: any) => {
    setPhone(trim(e.target.value));
  }, []);

  const onChangeCaptcha = useCallback((e: any) => {
    setCaptcha(trim(e.target.value));
  }, []);

  const goBack = useCallback(() => {
    setCaptchaed(false);
    setCaptcha("");
    setCaptchaRes({});
  }, []);

  const onValidateCaptcha = useCallback(() => {
    if (!validateCode(captcha)) {
      notificationCall.error({
        message: "验证码不正确",
      });
      return;
    }
    setLoading(true);
    AxiosService.post("/api/validate-captcha", {
      phone: `+86${phone}`,
      captcha,
      r: captchaRes.randstr,
      t: captchaRes.ticket,
    })
      .then((res: any) => {
        setUserInfo(res?.user, res?.token);
        if (res?.user?.nickname) {
          navigate("/chat");
        } else {
          setIsRegister(true);
        }
      })
      .catch((err) => {
        notificationCall.error({
          message: prop("error")(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [captcha, captchaRes, notificationCall, phone, setUserInfo, navigate]);

  const onResend = useCallback(() => {
    onSendCaptcha(captchaRes);
  }, [onSendCaptcha, captchaRes]);

  const Form = useMemo(() => {
    if (!captchaed) {
      return (
        <div className="p-8 rounded  ">
          <Input
            className="mb-6 text-[16px] w-full h-[48px] "
            value={phone}
            onChange={onChangePhone}
            placeholder="请输入手机号"
          />
          <Button
            type="primary"
            loading={loading}
            className="mb-4 text-[16px] w-full h-[48px] "
            onClick={onValidateHuman}
          >
            下一步
          </Button>
        </div>
      );
    }
    if (isRegister) {
      return (
        <div className="p-8 rounded  ">
          <Init />
        </div>
      );
    }

    return (
      <div className="p-8 rounded  ">
        <div className="text-white flex justify-center mb-2   text-[24px]">
          输入 6 位验证码
        </div>
        <div className="text-gray-100  flex justify-center mb-4  text-[14px]">
          验证码已发送至 +86 {phone}
        </div>
        <Input
          className="mb-6 text-[16px] w-full h-[48px] "
          value={captcha}
          onChange={onChangeCaptcha}
          placeholder="请输入验证码"
        />

        <Button
          type="primary"
          loading={loading}
          className="mb-4 text-[16px] w-full h-[48px] "
          onClick={onValidateCaptcha}
        >
          下一步
        </Button>
        <div className="flex justify-center mb-4">
            <Timer onResend={onResend} />
        </div>
      </div>
    );
  }, [
    captcha,
    captchaed,
    onChangeCaptcha,
    onChangePhone,
    onValidateHuman,
    phone,
    onValidateCaptcha,
    isRegister,
    loading,
    onResend,
  ]);

  return (
    <div
      className={`w-full flex justify-center items-center  h-[100vh] bg-gray-700 p-8  ${
        !captchaed && styles.main
      } `}
    >
      {captchaed && (
        <LeftOutlined
          className="absolute top-4 left-4 text-white text-[24px] cursor-pointer"
          onClick={goBack}
        />
      )}
      {contextHolder}
      <div className="w-[440px]  ">
        {!captchaed && (
          <div>
            <div className="text-[40px] flex justify-center  text-white font-logo font-bold  ">
              Chapose
            </div>
            <div className="flex mb-4  text-[30px] text-white  font-bold justify-center  ">
              来，创造一个新的世界
            </div>
          </div>
        )}
        {Form}
      </div>
      <Copyright />
    </div>
  );
};

export default Sign;
