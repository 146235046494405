// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import { HISTORY } from "../constants/history";
import LocalStorageService from "../service/LocalStorageService";

export type HistoryStore = {
  history: any[];
  getHistory: () => void;
  deleteHistory: (id: string) => void;
  refreshHistory: (chat: any) => void;
};

export const useHistoryStore = create<HistoryStore>((set, get) => ({
  history: LocalStorageService.getItem(HISTORY),
  getHistory: () => AxiosService.get("/api/get-history").then((res: any) => {

      LocalStorageService.setItem(HISTORY, res);

      // setHasMore(list.length > 0);
      set({ history: res });
    }),
  deleteHistory: (id: string) => {
    // 根据id从本地存储中删除
    const list = get().history;
    const newList = list.filter((item: any) => item.id !== id);
    LocalStorageService.setItem(HISTORY, newList);
    set({ history: newList });
  },
  refreshHistory: (chat) => {
    /* 将传入的chat更新到history中 */
    const { history: oldHistory } = get();
    const newHistory = [...oldHistory];
    const index = newHistory.findIndex((item) => item.id === chat.id);
    if (index !== -1) {
      newHistory[index] = chat;
    } else {
      newHistory.unshift(chat);
    }
    LocalStorageService.setItem(HISTORY, newHistory);
    set({ history: newHistory });
  },
}));
