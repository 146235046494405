// 删除聊天记录
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { updateChatApi } from "../../../api/chat";
import { useHistoryStore } from "../../../store/history";
// 使用React的自定义Hook，传入聊天信息
export const useDeleteChat = () => {
  // 使用react-router-dom的useNavigate，可以让我们更容易的进行页面的跳转
  const navigate = useNavigate();
  // 使用自定义的Hook，可以直接返回我们对历史记录进行操作的方法
  const { deleteHistory } = useHistoryStore();
  // useCallback返回一个固定的缓存函数，当info、navigate、deleteHistory改变的时候，会返回新的函数
  const onDelete = useCallback((info: any)=> () => {
    // 进行页面的跳转
    navigate("/chat");
    // 删除对应id的历史记录
    deleteHistory(info.id);
    // 更新聊天的API，将deleted设置为true
    updateChatApi({
      ...info,
      deleted: true,
    });
  }, [navigate, deleteHistory]);
  // 返回onDelete方法
  return {
    onDelete,
  };
};