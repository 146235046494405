
export const DEFAULT_AMOUNT = {
    value: 0.01,
    label: '600豆',
};

export const AMOUNT_LIST =[
  
  {
    value: 0.01,
    label: '600豆',
  },
  {
    value: 1,
    label: '100豆',
  },
  {
    value: 30,
    label: '3000豆',
  },
  {
    value: 98,
    label: '9800豆',
  },
  {
    value: 298,
    label: '29800豆',
  },
  {
    value: 598,
    label: '59800豆',
  },
];