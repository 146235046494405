// 导入默认模型和默认角色设定
import { DEFAULT_MODEL } from "../../../constants/model";
import { DEFAULT_ROLE } from "../../../constants/universe";
// CHAT_INIT对象声明了聊天应用在初始化状态下需要的一些基本属性
// 它定义了以下属性：
// sending: 表示是否正在发送信息的状态标识，默认值为false
// currentQuestions: 存储当前问题的数组
// currentAnswers: 存储当前回答的数组
// model: 当前所使用的模型，默认为DEFAULT_MODEL 
// role: 用户在聊天中的角色，默认为DEFAULT_ROLE
export const CHAT_INIT = {
    sending: false,
    currentQuestions: [],
    currentAnswers: [],
    model: DEFAULT_MODEL,
    role: DEFAULT_ROLE,
}