// store.ts
import { create } from "zustand";
import AxiosService from "../api/AxiosService";
import { CHARACTER, SYSTEM_CHARACTER, DEFAULT_ROLE } from "../constants/universe";
import LocalStorageService from "../service/LocalStorageService";
import { compact, normalize, omit, filter, find } from "../utils/fp";

export type Store = {
  character: any[];
  characterMap: any;
  getMyCharacter: () => void;
  refreshCharacter: (character: any) => void;
  removeCharacter: (id: string) => void;
  getDefaultRole: () => string;
};

const mergeSystem = (res: any) =>{
  const hasDefault = find((item: any) => item.isDefault)(res);
  const defaultRole = SYSTEM_CHARACTER;
  if (hasDefault) {
    defaultRole.isDefault = false;
  }
  const list = compact([SYSTEM_CHARACTER, ...(res || [])]);
  return list;
};

export const useCharacterStore = create<Store>((set, get) => ({
  character: mergeSystem(LocalStorageService.getItem(CHARACTER)),
  characterMap: normalize(mergeSystem(LocalStorageService.getItem(CHARACTER))),
  getMyCharacter: () =>
    AxiosService.get("/api/get-my-characters").then((res: any) => {
      LocalStorageService.setItem(CHARACTER, res);
      set({
        character: mergeSystem(res),
        characterMap: normalize(mergeSystem(res)),
      });
    }),
  refreshCharacter: (character) => {
    /* 将传入的character更新到character和characterMap 中 */
    const { character: oldCharacter, characterMap: oldCharacterMap } = get();
    const newCharacter = [...oldCharacter];
    const newCharacterMap = { ...oldCharacterMap };
    const index = newCharacter.findIndex((item) => item.id === character.id);
    if (index !== -1) {
      newCharacter[index] = character;
      newCharacterMap[character.id] = character;
    } else {
      newCharacter.unshift(character);
      newCharacterMap[character.id] = character;
    }
    set({ character: newCharacter, characterMap: newCharacterMap });
  },
  removeCharacter: (id: string) => {
    /* 将传入的character更新到character和characterMap 中 */
    const { character, characterMap } = get();

    set({
      character: filter((item: any) => item.id !== id)(character),
      characterMap: omit(id)(characterMap),
    });
  },
  getDefaultRole: () => {
    const { character } = get();
    const defaultRole = character.find((item) => item.isDefault);
    return defaultRole ? defaultRole.id : DEFAULT_ROLE  as string;
  }
}));
