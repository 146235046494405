// 输入法切换 hook
import { useCallback, useState } from "react";

export const useComposition = () => {
  const [isComposing, setIsComposing] = useState(false); // 输入法切换
  // 输入法切换开始
  const handleCompositionStart = useCallback(() => {
    setIsComposing(true);
  }, []);
  // 输入法切换结束
  const handleCompositionEnd = useCallback(() => {
    setIsComposing(false);
  }, []);
  return {
    isComposing,
    handleCompositionStart,
    handleCompositionEnd,
  };
};
