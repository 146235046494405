// 更新用户信息
import { useCallback } from "react";
import { useUserStore } from "../../../store/user";
import { TOKEN_KEY, USER_INFO } from "../../../constants/user";
import LocalStorageService from "../../../service/LocalStorageService";

export const useSetUserInfo = () => {
  const { setUser } = useUserStore();

  const setUserInfo = useCallback(
    (user: any, token?: string) => {
      if(token){
        LocalStorageService.setItem(TOKEN_KEY, token);
      }
    
      LocalStorageService.setItem(USER_INFO, user);
      setUser(user);
    },
    [setUser],
  );

  return {
    setUserInfo,
  };
};
