// 导入React的memo以进行优化，使组件在接收相同属性时，不会重新渲染。
import { memo } from "react"; 
// 导入react-router-dom提供的Outlet，用于在子路由之间导航。
import { Outlet } from "react-router-dom"; 
// 导入自定义的导航栏组件Nav。
import Nav from "./components/Nav/index";
// 定义Layout组件，该组件包括导航栏Nav和内容区域Outlet。
const Layout = () => (
    <div className="flex-1">
      <div className="overflow-hidden w-full h-full relative flex z-0 h-screen ">
        <Nav />
        <div className="relative flex h-full max-w-full flex-1 overflow-hidden ">
          <Outlet />
        </div>
      </div>
    </div>
  );
// 使用memo包裹Layout组件优化性能，导出该组件。
export default memo(Layout);
