export const UNIVERSE = 'universe';
export const CHARACTER = 'character';

export const DEFAULT_ROLE = '0';

export const SYSTEM_CHARACTER =  {
    id: DEFAULT_ROLE,
    nickname: "Ava",
    avatar: 'https://chapose.com/logo192.png',
    intro:"嗨，我是Ava",
    description:`
    你是ChatGPT，一个由OpenAI训练的大型语言模型。
    请尽可能清晰准确的回答问题，回答之前请思考你答案的正确性。
    答案经过润色，让人读起来清晰易懂。`,
    isDefault: true,

};