/* 一个展示用户信息的卡片组件 */
import { useCallback, useState } from "react";
import AxiosService from "../../../api/AxiosService";
import { useCharacterStore } from "../../../store/character";

export function useNewCharacter() {
  const { refreshCharacter } = useCharacterStore();
  const [loading, setLoading] = useState(false);

  const onNewCharacter = useCallback(
    (character: any) => {
      setLoading(true);
      return AxiosService.post("/api/new-character", character)
        .then((res: any) => {
          if (res) {
            refreshCharacter(res);
          }
          return res;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [refreshCharacter]
  );

  return {
    loading,
    onNewCharacter,
  };
}
