import { memo, useState, useCallback } from "react";
import { notification } from "antd";
import AxiosService from "../../../api/AxiosService";

import { useCharacterStore } from "../../../store/character";

interface Props {
  active: any;
  setActive: (active: any) => void;
}

const Role = ({ active, setActive }: Props) => {
  const { removeCharacter } = useCharacterStore();

  const [loading, setLoading] = useState(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const onRemove = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      AxiosService.post("/api/remove-character", {
        id: active.id,
      })
        .then(() => {
          notificationCall.success({
            message: "已删除",
          });
          removeCharacter(active.id);
          setActive({});
        })
        .catch(() => {
          notificationCall.error({
            message: "删除失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [active.id, removeCharacter, loading, setActive]
  );

  return (
    <>
      {contextHolder}

      <div onClick={onRemove}>删除</div>
    </>
  );
};

export default memo(Role);
