import { useEffect, useState, useMemo, useCallback } from "react";
import { Empty } from "antd";
import PublishNew from "./components/Publish/New";
import AxiosService from "../../api/AxiosService";
import {
  flow,
  map,
  mapIndexed,
  isEmpty,
  prop,
  groupBy,
  concat,
} from "../../utils/fp";
import Update from "./components/Publish/Update";
import { useUniverseStore } from "../../store/universe";
import Card from "./components/Publish/Card";
import { DEFAULT_ROLE } from "../../constants/universe";

function Publish() {
  const [list, setList] = useState<any[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [editCharacter, setEditCharacter] = useState<any>(null);

  const { schoolMap } = useUniverseStore();

  const onRefresh = useCallback(() => {
    AxiosService.get("/api/get-my-publish-characters").then((res: any) => {
      setList(res);
    });
  }, []);

  useEffect(() => {
    onRefresh();
  }, []);

  const exclude: string[] = useMemo(
    () =>
      flow(
        map((item: any) => item.source),
        concat([DEFAULT_ROLE])
      )(list),
    [list]
  );

  const catagory = useMemo(
    () => groupBy((item: any) => item.school)(list),
    [list]
  );

  return (
    <div className="w-full bg-gray-100 h-full p-[20px] overflow-y-auto">
      <div className="flex mb-4">
        <div className="text-2xl font-bold mr-4">已上架</div>
        <PublishNew callback={onRefresh} exclude={exclude} />
      </div>
      <div />

      <div className="flex flex-col">
        {mapIndexed((characters: any, index: string) => (
          <div
            key={index}
            className=" bg-white  p-[20px] relative mb-[20px] rounded-xl "
          >
            <div className="mb-4 px-[20px] font-medium ">
              {prop(`${index}.name`)(schoolMap)}
            </div>

            <div className="flex flex-wrap">
              {map((character: any) => (
                <Card
                  key={character.id}
                  character={character}
                  refreshChat={onRefresh}
                  setOpenEdit={setOpenEdit}
                  setEditCharacter={setEditCharacter}
                />
              ))(characters)}
            </div>
          </div>
        ))(catagory)}

        {isEmpty(list) && (
          <div className="w-full  p-8 flex justify-center">
            <Empty description="暂无数据" />
          </div>
        )}
      </div>
      <Update
        key={editCharacter?.id}
        callback={onRefresh}
        isModalOpen={openEdit}
        character={editCharacter}
        setIsModalOpen={setOpenEdit}
      />
    </div>
  );
}

export default Publish;
