import { memo, useState, useCallback } from "react";
import { notification, Modal } from "antd";
import AxiosService from "../../../api/AxiosService";
import { useCharacterStore } from "../../../store/character";
import { DEFAULT_ROLE } from "../../../constants/universe";

const { confirm } = Modal;
interface Props {
  active: any;
  setActive: (active: any) => void;
}

const SetDefault = ({ active, setActive }: Props) => {
  const { character, refreshCharacter } = useCharacterStore();

  const [loading, setLoading] = useState(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const updateCharacter = useCallback(
    (data: any) => {
      if (data.id === DEFAULT_ROLE) {
        return Promise.resolve(data);
      }
      return AxiosService.post("/api/update-character", data);
    },
    [refreshCharacter]
  );

  const setDefault = useCallback(
    (defaultCharacter: any) => {
      if (loading) {
        return;
      }
      setLoading(true);
      const promises = [
        updateCharacter({
          ...active,
          isDefault: true,
        }).then((res: any) => {
          refreshCharacter(res);
          setActive(res);
          return res;
        }),
      ];
      if (defaultCharacter) {
        promises.push(
          updateCharacter({
            ...defaultCharacter,
            isDefault: false,
          }).then((res: any) => {
            refreshCharacter(res);

            return res;
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          notificationCall.success({
            message: `【${active.nickname}】已设为默认角色`,
          });
        })
        .catch(() => {
          notificationCall.error({
            message: "设置失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [active.id, refreshCharacter, updateCharacter, loading]
  );

  const onSetDefault = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (active.isDefault) {
        return;
      }
      const defaultCharacter = character.find((item: any) => item.isDefault);
      confirm({
        content: `${
          defaultCharacter ? `默认角色为【${defaultCharacter.nickname}】,` : ""
        }确定要将【${active.nickname}】设为默认吗？`,
        onOk() {
          setDefault(defaultCharacter);
        },
      });
    },
    [setDefault]
  );

  return (
    <>
      {contextHolder}

      <div onClick={onSetDefault}>
        {active.isDefault ? "默认角色" : "设为默认"}
      </div>
    </>
  );
};

export default memo(SetDefault);
