// 返回随机的提示信息
export  function getRandomInstruction() {
    const instructions = [
        "Enter发送",
        "Shift+Enter换行",
        "上下箭头切换问题",
        "拖动可改变输入框大小",
        "发消息...",
        "发消息...",
        "发消息...",
        "发消息...",
        "发消息...",
        "发消息..."
    ];
    const randomIndex = Math.floor(Math.random() * instructions.length);
    return instructions[randomIndex];
}