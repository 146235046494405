import Markdown from "../../components/Markdown/index";

function Terms() {
  return (
    <div className="flex justify-center p-4">
      <div className="w-[900px]">
        <Markdown>{`# 用户协议

## 一、总则

### 1.1 总述

本协议是您成都华度唯度科技有限公司（以下简称“本公司”）之间关于您使用Chapose网站（以下简称“本网站”）服务所订立的协议。本协议描述本公司与您之间关于“服务”使用与提供的权利义务。“用户”是指注册、登录、使用、浏览本网站的个人或组织。

### 1.2 注意事项

请您仔细阅读并遵守本协议，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议。限制、免责条款可能将以**加粗**或_加下划线_的形式提示您重点注意。

### 1.3 年龄限制

您未满18岁的，应在法定监护人的陪同下阅读本协议。

### 1.4 协议接受

您使用本网站的任何服务即视为您已阅读并同意本协议的约束。

## 二、服务内容

### 2.1 服务描述

本网站的具体内容由本公司根据实际情况提供，包括但不限于授权您通过其帐号进行在线编程、上传资料、下载软件等。

### 2.2 账户需求

本服务的使用可能需要用户注册一个账号和密码，用户应妥善保管。

## 三、用户须知

### 3.1 法律遵守

用户在使用本网站服务时，必须遵守相关法律法规的规定，不得在本网站发布、传送、传播违法、恶意、诈骗性质的信息。

### 3.2 技术限制

用户不得对本网站进行反向工程、反向汇编、反向编译等。

### 3.3 信息安全

用户应当妥善保管注册账户信息，因用户原因导致账户信息泄露所造成的任何损失或损害，本公司不负责任。

## 四、免责与责任限制

### 4.1 设备和费用

本公司仅提供相关服务，除此之外与服务有关的设备（例如个人电脑、手机等与接入互联网或移动互联网有关的装置）及所需的费用（例如接入互联网的费用、使用移动网的费用）均应由用户自行负担。

### 4.2 风险承担

用户明确同意其使用本公司服务所存在的风险将完全由其自己承担。

### 4.3 不可抗力

本公司不对因不可抗力或本公司不能控制的原因造成的网络服务中断或其他缺陷，承担任何责任。

## 五、协议修改

### 5.1 修改权

本公司有权随时修改本协议的任何条款，一旦本协议的内容发生变动，本公司将会直接在本网站上公布修改之后的协议内容。

### 5.2 用户责任

如果不同意本公司对本协议相关条款所做的修改，用户有权停止使用本网站提供的服务。如果用户继续使用本网站提供的服务，则视为接受本公司对本协议相关条款所做的修改。

## 六、法律适用和管辖

### 6.1 法律适用

本协议的订立、执行和解释及争议的解决均应适用当地法律。

### 6.2 争议解决

若您和本公司之间发生服务纠纷，应首先本着诚信原则通过协商加以解决。如果协商不成，则应向本公司注册地的有管辖权的人民法院提起诉讼。

---

以上协议最终解释权归成都华度纬度科技有限公司所有，并且保留一切解释和修改的权力。

日期: 2023年9月1日

`}</Markdown>
      </div>
    </div>
  );
}

export default Terms;
