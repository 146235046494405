import { Avatar } from "antd";
import { memo } from "react";
import { useGo } from "../../../../hooks/useGo";
import { getAvatrUrl } from "../../../../utils/avatar";
import ChatButton from "./ChatButton";

import style from "./style.module.scss";

function Card({ character }: { character: any }) {
  const { navigate } = useGo();

  return (
    <div
      key={character.id}
      onClick={() => navigate(`/discover/character/${character.id}`)}
      className="p-[16px] cursor-pointer flex relative hover:bg-gray-100  group w-[360px]  rounded-xl"
    >
   
      <Avatar
        className="w-[90px] h-[90px] rounded-[20px!important] mr-2 "
        shape="square"
        src={getAvatrUrl(character)}
      />
      <div className="flex-1 flex flex-col justify-between ">
        <div className="mb-1 w-full flex justify-between flex-1 ">
          {character.nickname} 
          <div className="hidden group-hover:flex"><ChatButton character={character} /></div>
        </div>
        <div className={`text-sm text-gray-500 ${style.truncate}`}>
          {character.intro}
        </div>
      </div>
    </div>
  );
}

export default memo(Card);
