import { memo, useCallback, useMemo } from "react";
import { map, find, prop } from "../../../../utils/fp";
import CharacterSelect from "../../../../components/Character/Select";
import { MODEL_LIST } from "../../../../constants/model";
import { useChatStore } from "../../store/index";

const Icon = (d: any, className: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    className={`h-4 w-4 transition-colors ${className}`}
    width="16"
    height="16"
    strokeWidth="2"
  >
    <path d={d} fill="currentColor" />
  </svg>
);

export const findModel = (key: string) =>
  find((item: any) => item.key === key)(MODEL_LIST);

type ModelProps = {
  chatId: string;
};

const Model = ({ chatId }: ModelProps) => {
  const { chatMap, refreshChat } = useChatStore();
  const chat = useMemo(() => prop(chatId)(chatMap), [chatMap, chatId]);

  const onChangeModel = useCallback(
    (model: string) => () => {
      refreshChat(chatId, {
        model,
      });
    },
    []
  );
  const onChangeRole = useCallback((role: string) => {
    refreshChat(chatId, {
      role,
    });
  }, []);

  const role = useMemo(() => chat?.role, [chat]);

  return (
    <div className="flex flex-col  justify-center items-center">
      <div className="flex flex-col mt-12  mb-4  justify-center items-center">
        <div className="bg-gray-100 text-gray-900  flex p-2 rounded-xl mb-2">
          {map((item: any) => {
            const active = item.key === chat?.model;
            const activeClass = active
              ? "bg-white shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)] "
              : "";
            return (
              <div
                onClick={onChangeModel(item.key)}
                key={item.key}
                className={`flex cursor-pointer pl-12 pr-12 py-3   rounded-lg  ${activeClass} `}
              >
                <span className="relative max-[370px]:hidden pr-2">
                  {Icon(
                    item.icon,
                    active ? "text-brand-green" : "text-gray-400"
                  )}
                </span>
                <span className="font-medium text-sm">{item.name}</span>
              </div>
            );
          })(MODEL_LIST)}
        </div>
      </div>
      <CharacterSelect role={role} setRole={onChangeRole} />

      <div className="mt-8 text-gray-300 text-[36px]  font-logo">Chapose</div>
    </div>
  );
};

export default memo(Model);
