import { memo, useState, useEffect, useCallback } from "react";
import { Avatar, Button } from "antd";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { isEmpty } from "../../utils/fp";
import { useGo } from "../../hooks/useGo";
import { useCharacterStore } from "../../store/character";
import { getAvatrUrl } from "../../utils/avatar";
import More from "./components/More"
import Nav from "./components/Nav"

const Role = () => {
  const { character } = useCharacterStore();
  const [active, setActive] = useState<any>({});

  useEffect(() => {
    if (character && isEmpty(active)) {
      setActive(character[0]);
    }
  }, [character, active]);

  const { navigate } = useGo();

  useDocumentTitle("角色");

  const goChat = useCallback(() => {
    const source = active.id;
    if (source) {
      return navigate(`/chat/?character=${source}`);
    }
  }, [active.id, navigate]);

  return (
    <div className="flex w-full h-full">
      <Nav active={active} setActive={setActive} />
       
      <div className="flex-1  bg-white  relative overflow-y-auto  ">
        <div className="flex p-4 flex-col  bg-gray-100 items-center justify-center relative  ">
          <Avatar
            shape="square"
            className="w-[100px]  h-[100px]"
            src={getAvatrUrl(active)}
          />
          <div className="mt-[10px] mb-[10px]">{active?.nickname}</div>
          <div className="flex items-center">
            <Button
              onClick={goChat}
              type="primary"
              className="rounded-[20px] mr-2 w-[120px] px-[20px] py-[4px]"
            >
              聊天
            </Button>
            <More active={active} setActive={setActive} />
           
          </div>
        </div>
        <div className="max-w-[800px] m-auto">
          <div className="flex p-[20px] ">
            <div className="w-[60px]  text-gray-500">介绍</div>{" "}
            <div className="flex-1 whitespace-pre-wrap">
              {active?.intro || "-"}
            </div>
          </div>
          <div className="flex px-[20px] pb-[20px]">
            <div className="w-[60px]  text-gray-500">设定</div>
            <div className="flex-1 whitespace-pre-wrap">
              {active?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Role);
