import { ChatError, ErrorCode } from './errors'
// 事件类型枚举
export enum EventType {
  UPDATE_ANSWER = 'UPDATE_ANSWER',
  DONE = 'DONE',
  ERROR = 'ERROR'
}
// 定义事件类型
export type Event =
  | {
      type: EventType.UPDATE_ANSWER
      data: {
        text: string
      }
    }
  | {
      type: EventType.DONE
      id: string
    }
  | {
      type: EventType.ERROR
      error: ChatError
    }
// 发送消息参数接口
export interface SendMessageParams {
  prompt: string,
  context: any[]
  onEvent: (event: Event) => void
  signal?: AbortSignal
  id?: string
}
// 抽象Bot类
export abstract class AbstractBot {
  async sendMessage(params: SendMessageParams, config?: any) {
    try {
      await this.doSendMessage(params, config)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      if (err instanceof ChatError) {
        params.onEvent({ type: EventType.ERROR, error: err })
      } else if (!params.signal?.aborted) {
        // 用户取消异常
        params.onEvent({ type: EventType.ERROR, error: new ChatError((err as Error).message, ErrorCode.UNKOWN_ERROR) })
      }
    }
  }

  abstract doSendMessage(params: SendMessageParams, config?:any ): Promise<void>
}
 