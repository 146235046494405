// import { LanguageSetting } from "../../components/Header/LanguageSetting";
import { QRCode, Button, Modal, notification } from "antd";
import { useState, useCallback } from "react";
import { AMOUNT_LIST, DEFAULT_AMOUNT } from "./constants";
import { map } from "../../utils/fp";
import CurrencyDisplay from "../../components/Currency";
import BaseInfo from "./components/BaseInfo";
import AxiosService from "../../api/AxiosService";
import { usePolling } from "./hooks/usePolling";

const Profile = () => {
  const [active, setActvie] = useState<any>(DEFAULT_AMOUNT);
  const [open, setOpen] = useState(false);


  const [paySetting, setPaySetting] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const [notificationCall, contextHolder] = notification.useNotification();

  const fetchData = async (traderNo: string) => {
    const res: any = await AxiosService.get(`/api/get-pay-info?id=${traderNo}`);
    return res;
  };

  const callback = useCallback(() => {
    notificationCall.success({
      message: "支付成功",
    });
  },[]);

  const {
    stopPolling, 
    startPolling
  } = usePolling(
    fetchData,
    2000,
    callback
  );

  const getPayUrl = useCallback((amount: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    stopPolling();
    AxiosService.post("/api/pay", {
      amount,
    })
      .then((res: any) => {
        if (res && res?.code === 0) {
          setPaySetting(res);
          startPolling(res.traderNo);
        }
        setOpen(true);
      })
      .catch(() => {
        notificationCall.error({
          message: "下单失败，请稍后再试",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChangeAmount = useCallback(
    (amount: any) => () => {
      setActvie(amount);
     // getPayUrl(amount.value);
    },
    []
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    getPayUrl(active.value);
  }, [active]);

  return (
    <div className="p-[20px]">
      {contextHolder}
      <div className="mb-6">
        <div className="text-[18px] font-medium mb-2">充值给</div>
        <BaseInfo />
      </div>
      <div className="mt-2">
        <div className="text-[18px] font-medium mb-1">充值金额</div>
        <div className="flex flex-wrap w-[700px]">
          {map((item: any) => (
            <div
              onClick={onChangeAmount(item)}
              className={`${
                item.value === active.value &&
                "border-[#ff2d55!important] bg-[rgba(254,44,85,.1)]"
              } cursor-pointer mr-4 mb-4 rounded-lg bg-gray-100  flex flex-col items-center justify-center  w-[210px] h-[80px] border`}
            >
              <div className="mb-1">
                <span className="font-medium">{item.label}</span>
              </div>
              <span className="text-gray-500">
                <CurrencyDisplay amount={item.value} />
              </span>
            </div>
          ))(AMOUNT_LIST)}
        </div>
      </div>
      <div className="mt-2">
        <div className="text-[18px] font-medium">
          应付金额{" "}
          <span className="text-[#ff2d55]  text-[30px]">
            {" "}
            <CurrencyDisplay amount={active.value} />{" "}
          </span>
        </div>
        <Button
          type="primary"
          loading={loading}
          onClick={onOpen}
          className="bg-[#ff2d55!important] border-[#ff2d55!important] mt-2 w-[300px] h-[60px] flex justify-center items-center "
        >
          <span className="font-bold text-[20px]">立即充值</span>
        </Button>
        <Modal
          width={800}
          title="支付"
          open={open}
          footer={null}
          onCancel={onClose}
        >
          <div className="">
            <div className="">
              <div className="mb-1">
                订单提交成功，请尽快完成付款！支付订单号：{paySetting?.traderNo}
              </div>
              <div className="text-[16px] flex items-center font-medium">
                <div className="mr-1">
                  应付金额{" "}
                  <span className="text-[#ff2d55]  text-[20px]">
                    {" "}
                    <CurrencyDisplay amount={active.value} />{" "}
                  </span>
                  (<span>{active.label}</span>)
                </div>
              </div>
              <div className="text-[16px] flex items-center font-medium">
                {" "}
                收款方：Chapose
              </div>
            </div>
            <div className="p-6 flex flex-col items-center justify-center">
              <QRCode size={240} value={paySetting?.data} />
              <div className="flex items-center mt-3">
                <img
                  className="light"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAgBAMAAAAYrL5yAAAALVBMVEX///////8C424A4Wj////2/vpI65cZ5Xva++ox6Imr9tDE+d3o/fJu8K2U9MPh9z8XAAAAAnRSTlPhI5ORfucAAAENSURBVCjPYxBEBQIMGMAFFThABFyhGC7g4tPRg6zCde/0ovLcLXAB15XGxkrKxmZLYALHjJVAQNnsCETAZ7ISBCjPgAicNIIJmEEEkuH8pWABb6gCZbWlPrdAAm5GSiBDjdW2uD43Awm0GylnAsVqj7hsM1JvAQosVlK+esl4XotLr7KS8hIHhpDHSko63rdCXOKKgeY8CQAL2AFd5z3ZCCIA1KJkeSTEIwlkGUgL0FAg7XodbLl5C8Ra5YyVEMeArfUCstUh3lOygjldGerYFLBAO8xzQCPAAt7FMN9VQwMI6n9lsxaogK8ROAgtn8KC0NPo7nQl9WlL4YF8aLZLxO4dIYhoiArBiDmSBAC1GYcB10LzZgAAAABJRU5ErkJggg=="
                  alt=""
                />
                请用微信扫码完成支付
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
