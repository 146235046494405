// 聊天页
import { useCallback, useMemo, memo, useEffect } from "react";
import { notification } from "antd";
import { nanoid } from "nanoid";
import { shallow } from "zustand/shallow";
import {
  prop,
  isEmpty,
  noEmpty,
  replaceItem,
  find,
  includes,
  concat,
  last,
} from "../../utils/fp";
import { ChatGPTApiBot, makeContext } from "../../api/chatgpt-api";
import { useChatStore } from "./store/index";
import { createQuestion } from "../../utils/createTempData";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useCharacterStore } from "../../store/character";
import Info from "./components/Info/index";
import History from "./components/History/index";
import { useGo } from "../../hooks/useGo";
import ChatBox from "./components/ChatBox/index";
import { useHistoryStore } from "../../store/history";
import { useChatId, NEW_CHAT_ID } from "./hooks/useChatId";
import { useController } from "./hooks/useController";
import { useScrollBottom } from "./hooks/useScrollBottom";
import { useCharacterId } from "./hooks/useCharacterId";

import { extractUsernames } from "./utils";

const bot = new ChatGPTApiBot();

// const { confirm } = Modal;

const selector = (state: any) => ({
  addQuestion: state.addQuestion,
  getChat: state.getChat,
  chatMap: state.chatMap,
  newChat: state.newChat,
  initChat: state.initChat,
  refreshChat: state.refreshChat,
});

const Chat = () => {
  const { wrapRef, scrollToBottom, isBottom, isScrollBottom, setBottom } =
    useScrollBottom();

  const { addQuestion, getChat, chatMap, newChat, initChat, refreshChat } =
    useChatStore(selector, shallow);
  const { chatId } = useChatId();
  const { characterId } = useCharacterId();
  useEffect(() => {
    initChat(chatId, { role: characterId });
  }, [initChat, chatId]);

  const chat = useMemo(() => chatMap[chatId], [chatMap, chatId]);
  const currentQuestions = useMemo(() => chat?.currentQuestions || [], [chat]);

  const { controller, initContoller } = useController();

  const { navigate } = useGo();

  const { characterMap, character } = useCharacterStore();

  // useEffect(() => {
  //   confirm({
  //     content: `余额不够，是否充值？`,
  //     onOk() {
  //       navigate('/profile/recharge');
  //     },
  //   });

  // },[])

  const [notificationCall, contextHolder] = notification.useNotification();

  const { refreshHistory } = useHistoryStore();

  const info = useMemo(() => chat?.info || {}, [chat]);

  const systemRole = useMemo(
    () => prop(chat?.role)(characterMap),
    [chat?.role, characterMap]
  );

  const onSendMessage = useCallback(
    async (prompt: string, newChatId?: string) => {
      const currentChatId = newChatId || chatId;
      let finalPrompt = prompt;
      initChat(NEW_CHAT_ID);

      const roles = extractUsernames(prompt);

      if (noEmpty(roles)) {
        const roleName = roles[0]?.replace("@", "");
        if (roleName) {
          const roleConfig = find(
            (item: any) => item.name === roleName,
            character
          );
          if (roleConfig) {
            finalPrompt = `${roleConfig.description} \n ${prompt}`;
          }
        }
      }

      const id = nanoid();

      let question = createQuestion(prompt, {
        id,
        chatId: currentChatId,
      });

      let newQuestions = concat(currentQuestions)(question);

      refreshChat(currentChatId, {
        currentQuestions: newQuestions,
        sending: id,
      });

      const context = makeContext(newQuestions, finalPrompt, systemRole.description);

      bot
        .doSendMessage(
          {
            id,
            prompt: finalPrompt,
            context,
            signal: controller?.signal,
            onEvent: (event) => {
             
              if (event.type === "DONE") {
                refreshChat(currentChatId, {
                  sending: null,
                });
                addQuestion(newQuestions, context).then((res: any) => {
                  refreshHistory(res);
                });
              } else {
                question = {
                  ...question,
                  answer: prop("data.text")(event),
                };
                newQuestions = replaceItem(id)(question)(newQuestions);
                refreshChat(currentChatId, {
                  currentQuestions: newQuestions,
                  sending: id,
                });

                if (isScrollBottom()) {
                  scrollToBottom();
                }
              }
            },
          },
          {
            model: info?.model || chat?.model,
            system: systemRole.description,
          }
        )
        .catch((e: any) => {
          // eslint-disable-next-line no-console
          console.error("error", e);
          if (!includes("aborted")(e.message)) {
            notificationCall.error({
              message: "发送失败",
              description: "请检查网络",
            });
          }
          refreshChat(newChatId || info?.id, {
            sending: null,
          });
        });
    },
    [
      addQuestion,
      currentQuestions,
      info,
      chat,
      systemRole,
      character,
      controller,
      chatId,
      isScrollBottom,
      scrollToBottom,
      refreshHistory,
    ]
  );
  const onSend = useCallback(
    (prompt: string) => {
      if (chat?.sending || isEmpty(prompt)) {
        return;
      }

      setBottom(!!isBottom());

      if (chatId === NEW_CHAT_ID) {
        const id = nanoid();
        navigate(`/chat/${id}`);
        newChat({
          title: prompt,
          lastMessage: prompt,
          id,
          model: chat?.model,
          role: chat?.role,
        }).then((res: any) => {
          if (res && res?.id) {
            refreshHistory(res);
          }
        });

        onSendMessage(prompt, id);
      } else {
        onSendMessage(prompt);
      }
    },
    [
      onSendMessage,
      newChat,
      refreshHistory,
      navigate,
      chat,
      setBottom,
      isBottom,
    ]
  );

  useEffect(() => {
    if (chatId) {
      getChat(chatId);
    }
  }, [chatId, getChat]);

  useDocumentTitle(systemRole?.nickname);

  const hasChat = useMemo(() => chatId !== NEW_CHAT_ID, [chatId]);

  // 停止回复
  const stopResponse = useCallback(() => {
    if (controller) {
      controller.abort();
      initContoller();
      refreshChat(chatId, {
        sending: null,
      });
      const newQuestion = prop("question")(last(currentQuestions));
      if (noEmpty(newQuestion)) {
        addQuestion(currentQuestions).then((res: any) => {
          refreshHistory(res);
        });
      }
    }
  }, [
    addQuestion,
    currentQuestions,
    refreshHistory,
    controller,
    initContoller,
    refreshChat,
  ]);

  return (
    <div className="relative flex flex-col h-full max-w-full flex-1  ">
      {contextHolder}
      {hasChat && <Info chatId={chatId} />}
      <History
        key={`history_${chatId}`}
        ref={wrapRef}
        hasChat={hasChat}
        chatId={chatId}
        systemRole={systemRole}
        scrollToBottom={scrollToBottom}
        isScrollBottom={isScrollBottom}
      />

      <ChatBox
        key={`input_${chatId}`}
        chatId={chatId}
        onSend={onSend}
        stopResponse={stopResponse}
      />
    </div>
  );
};

export default memo(Chat);
