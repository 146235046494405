import { useCallback, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useGo } from "../../../../hooks/useGo";
import List from "./List";
import { useHistoryStore } from "../../../../store/history";


const Nav = () => {

  const { history, getHistory } = useHistoryStore();

  const { navigate } = useGo();

  useEffect(() => {
    getHistory()
  }, [getHistory]);

  const onAdd = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  return (
    <div className="h-full border-r relative">
      <div className="flex h-full flex-col md:w-[300px] sm:w-full ">
        <div
          onClick={onAdd}
          className="  border-b  p-4  font-bold cursor-pointer"
        >
          <div className="w-full  rounded-xl item-center  justify-center bg-[#f3f3f3] py-4 flex   shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]   ">
            <div>
              <PlusCircleOutlined className="text-brand-green  text-[16px] mr-1" />
              <span className="text-brand-green"> 聊天</span>
            </div>
          </div>
        </div>

        <List  list={history} />
      </div>
    </div>
  );
};

export default Nav;
