import { includes } from "./fp";

export const getAvatrUrl = (user: any) => {
  const avatar = user?.avatar;
  if (includes("http")(avatar)) {
    return avatar;
  }
  if (avatar) {
    return `https://chapose.azureedge.net${avatar}`;
  }

  return `https://chapose.com/logo192.png`;
};
