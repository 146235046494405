import Dexie from "dexie";
// 首先，定义数据的接口
interface Item {
  id?: string;
  characters: any;
}
// 然后，定义数据库，并指定数据表和表中数据的格式
class ChatDatabase extends Dexie {
  items: Dexie.Table<Item, string>; // string is the type of the primary key

  constructor() {
    super("SchoolDatabase");
    // 定义数据库版本和表结构
    this.version(1).stores({
      items: "id,name,intro,characters",
    });
    // 定义表对象
    this.items = this.table("items");
  }

  // 添加方法
  // 增加或更新数据
  async upsert(item: Item) {
    return this.items.put(item);
  }

  // 查询数据
  async get(id: string) {
    return this.items.get(id);
  }

  // 删除数据
  async deleteItem(id: string) {
    return this.items.delete(id);
  }

  // 获取所有数据
  async getAll() {
    return this.items.toArray();
  }

  // 修改数据
  async updateById(id: string, info: any, answers: any, questions: any) {
    return this.items.update(id, { info, answers, questions });
  }
}
export default ChatDatabase;
