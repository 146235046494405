import { Mentions } from "antd";
import type { KeyboardEvent } from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Resizable } from "re-resizable";
import { SendOne } from "@icon-park/react";
import styles from "./style.module.scss";
import {
  isEmpty,
  isFunction,
  noEmpty,
  nth,
  size,
  prop,
  includes,
  trim,
} from "../../../../utils/fp";
import LocalStorageService from "../../../../service/LocalStorageService";
import { INPUT_AREA_HEIGHT } from "./constant";
import { useComposition } from "./hooks/useComposition";
import { useAt } from "./hooks/useAt";
import { getRandomInstruction } from "./utils";
import { useChatStore } from "../../store/index";

let index = 0;

const ChatBox = ({ onSend, stopResponse, chatId }: any) => {
  const { isComposing, handleCompositionStart, handleCompositionEnd } =
    useComposition();

  const options = useAt();

  const { chatMap } = useChatStore();

 
  const [value, setValue] = useState<any>();

  const chat = useMemo(() => prop(chatId)(chatMap), [chatId, chatMap]);

  const currentQuestions = useMemo(() => chat?.currentQuestions || [], [chat]);

  const send = useCallback(() => {
    if (noEmpty(trim(value)) && !chat.sending) {
      onSend(trim(value));
      setValue("");
    }
  },[value,onSend, chat])

  // 切换的 index
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        if (!isComposing) {
          event.preventDefault();
          send()
        }
      }
      // 向上切换问题
      if (event.key === "ArrowUp") {
        event.preventDefault();
        if (isEmpty(prompt) || index !== 0) {
          index -= 1;
          if (index < -size(currentQuestions)) {
            index = -size(currentQuestions);
          }
          const question = nth(index)(currentQuestions)?.question;
          if (noEmpty(question)) {
            setValue(question);
          }
        }
      }
      // 向下切换问题
      if (event.key === "ArrowDown") {
        event.preventDefault();
        if (index !== 0) {
          index += 1;
          if (index > -1) {
            index = -1;
          }
          const question = nth(index)(currentQuestions)?.question;
          if (noEmpty(question)) {
            setValue(question);
          }
        }
      }
      if (!includes(event.key)(["Enter", "ArrowUp", "ArrowDown"])) {
        index = 0;
      }
    },
    [onSend, isComposing, prompt, setValue, value, currentQuestions]
  );

  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current as any;
    if (textarea && isFunction(textarea.focus)) {
      textarea?.focus();
    }
  }, []);

  const onResizeStop = useCallback(
    (event: MouseEvent | TouchEvent, direction: any, refToElement: any) => {
      LocalStorageService.setItem(
        INPUT_AREA_HEIGHT,
        refToElement?.offsetHeight
      );
    },
    []
  );

  const placeholder = useMemo(() => getRandomInstruction(), []);

  return (
    <Resizable
      defaultSize={{
        width: "100%",
        height: LocalStorageService.getItem(INPUT_AREA_HEIGHT) || 200,
      }}
      minHeight={200}
      maxHeight="70%"
      onResizeStop={onResizeStop}
      className="pl-4 pr-[74px] pt-4 pb-[40px]  border-t relative bg-[#f3f3f3]"
    >
      <Mentions
        ref={textareaRef}
        value={value}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        placeholder={placeholder}
        autoComplete="off"
        style={{ resize: "none" }}
        onKeyDown={handleKeyDown}
        onChange={setValue}
        className={styles.autosize}
        placement="top"
        rows={4}
        options={options}
      />
      <div
        onClick={send}
        className={`cursor-pointer ${
          isEmpty(prompt)
            ? "bg-white"
            : "bg-[#07c160]"
        }  py-[10px] px-[10px] shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)] rounded-xl absolute right-[20px] top-[20px]`}
      >
        <SendOne theme="outline" size="20" fill={isEmpty(prompt)?"#07c160":"#ffffff"}/>
        
      </div>
      <div className="flex text-[10px] justify-center  text-gray-300  w-full absolute left-[0px] right-[0px] bottom-[14px]">
        内容由AI生成，请仔细甄别，小心求证
      </div>
      {chat?.sending && (
        <div
          onClick={stopResponse}
          className="absolute border bg-white text-sm  py-1 px-3 text-gray-500  cursor-pointer right-[20px] top-[-50px] rounded-[20px]  "
        >
          停止回答
        </div>
      )}
    </Resizable>
  );
};

export default memo(ChatBox);
