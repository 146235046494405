// 向左滑动按钮
import { useContext, memo } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { VisibilityContext } from "react-horizontal-scrolling-menu";


function Left() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <div
      className={`${
        isFirstItemVisible ? "hidden" : "block"
      } flex items-center justify-center  rounded-[26px] bg-white absolute left-[-20px] top-[74px] z-[10] w-[52px] h-[52px] shadow-[0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)] `}
      onClick={() => scrollPrev()}
    >
      <LeftOutlined className="text-gray-600" />
    </div>
  );
}

export default  memo(Left)