import { memo, useState, useCallback, useMemo, useEffect } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { isFunction } from "../../utils/fp";
import AxiosService from "../../api/AxiosService";
import { useCharacterStore } from "../../store/character";
import UploadAvatar from "../Profile/UploadAvatar";
import { getAvatrUrl } from "../../utils/avatar";

type Props = {
  setRole?: (key: any, res?: any) => void;
  callback?: () => void;
  character?: any;
  children?: React.ReactNode;
  isCopy?: boolean;
};

const Role = ({ setRole, callback, character, children, isCopy }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { refreshCharacter } = useCharacterStore();

  useEffect(() => {
    if (character && !isCopy) {
      setIsEdit(true);
    }
  }, [character]);

  const [notificationCall, contextHolder] = notification.useNotification();

  const initialValues = useMemo(() => {
    if (character) {
      return {
        nickname: character.nickname,
        avatar: character.avatar,
        description: character.description,
        intro: character.intro,
      };
    }

    return {};
  }, [character]);

  const showModal = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setIsModalOpen(true);
      if (callback && isFunction(callback)) {
        callback();
      }
    },
    [callback]
  );

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);
      if (isEdit) {
        AxiosService.post("/api/update-character", {
          ...character,
          ...values,
        })
          .then((res) => {
            refreshCharacter(res);
            setIsModalOpen(false);
            notificationCall.success({
              message: "编辑成功",
            });
            form.resetFields();
          })
          .catch(() => {
            notificationCall.error({
              message: "编辑失败",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        AxiosService.post("/api/new-character", values)
          .then((res: any) => {
            if (res) {
              refreshCharacter(res);
              if (res.id && setRole) {
                setRole(res.id, res);
              }
              setIsModalOpen(false);
              notificationCall.success({
                message: "创建成功",
              });
              form.resetFields();
            }
          })
          .catch(() => {
            notificationCall.error({
              message: "创建失败",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [isEdit, character, refreshCharacter, setRole, notificationCall]
  );

  const onSaveAvatar = useCallback(
    (avatar: string) => {
      form.setFieldsValue({
        avatar,
      });
    },
    [form]
  );

  const initAvatar = useMemo(() => {
    if (character && character.avatar) {
      return getAvatrUrl(character);
    }
    return "";
  }, [character]);

  const CreateButton = useMemo(() => {
    if (children) {
      return <div onClick={showModal}>{children}</div>;
    }
    return (
      <div className="flex justify-between">
        <Button
          onClick={showModal}
          type="primary"
          className="w-full"
          size="large"
        >
          创建角色
        </Button>
      </div>
    );
  }, [showModal, children]);

  return (
    <div>
      {contextHolder}
      {isEdit ? (
        <div onClick={showModal} className="cursor-pointer">
          {children || (
            <div className="flex p-1 ">
              <FormOutlined className="ml-1 text-gray-500  text-[16px]" />
            </div>
          )}
        </div>
      ) : (
        CreateButton
      )}

      <Modal
        title={isEdit ? "编辑角色" : "创建角色"}
        open={isModalOpen}
        footer={null}
        width={800}
        onCancel={hideModal}
        zIndex={2000}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            label="昵称"
            name="nickname"
            rules={[{ required: true, message: "你需要设置一个角色的昵称！" }]}
          >
            <Input placeholder="你想以什么样的角色昵称来玩？" />
          </Form.Item>
          <Form.Item
            label="头像"
            name="avatar"
            rules={[{ required: true, message: "你需要上传头像！" }]}
          >
            <UploadAvatar onUpload={onSaveAvatar} avatar={initAvatar} />
          </Form.Item>

          <Form.Item
            label="设定"
            name="description"
            rules={[{ required: true, message: "你需要设置角色的设定！" }]}
          >
            <Input.TextArea
              placeholder="你能告诉我角色的设定吗？比如说，他是一位资深的软件开发工程师，能够完成某些特定的工作。"
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>
          <Form.Item label="介绍" name="intro">
            <Input.TextArea
              placeholder="可以为角色设置一份介绍，让你更好的了解这个角色。"
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            保存
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Role);
