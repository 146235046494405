/* 一个展示用户信息的卡片组件 */
import { memo } from "react";
import { Avatar } from "antd";
import { getAvatrUrl } from "../../../../utils/avatar";
import ChatButton from "../School/ChatButton";

interface Props {
  character: any;
}

function Cover({ character }: Props) {
  return (
    <div className="flex p-4 flex-col  bg-gray-100 items-center justify-center relative  ">
      <Avatar
        shape="square"
        className="w-[100px]  h-[100px]"
        src={getAvatrUrl(character)}
      />
      <div className="mt-[10px] mb-[10px]">{character?.nickname}</div>
      
      <ChatButton character={character} />
    </div>
  );
}

export default memo(Cover);
