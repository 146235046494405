import { memo, useState, useCallback, useMemo } from "react";
import { Modal, Form, Button, notification, Select } from "antd";
import { useUniverseStore } from "../../../../store/universe";
import { map, isFunction } from "../../../../utils/fp";
import AxiosService from "../../../../api/AxiosService";

type Props = {
  callback?: () => void;

  character?: any;
  isModalOpen: boolean;
  setIsModalOpen: (key: boolean) => void;
};

const Role = ({ callback, character, isModalOpen, setIsModalOpen }: Props) => {
  const [loading, setLoading] = useState(false);

  const { schools, updateSchool } = useUniverseStore();

  const [notificationCall, contextHolder] = notification.useNotification();

  const options = map((item: any) => ({
    label: item.name,
    value: item.id,
  }))(schools);

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const initialValues = useMemo(() => {
    if (character) {
      return {
        school: character.school,
      };
    }

    return {};
  }, [character]);


  const onFinish = useCallback(
    (values: any) => {
      const meta = {
        ...character,
        ...values,
      };
      setLoading(true);
      AxiosService.post("/api/update-publish-character", meta)
        .then(() => {
          setIsModalOpen(false);
          notificationCall.success({
            message: "更新成功",
          });
          if (callback && isFunction(callback)) {
            callback();
          }
          updateSchool(values.school);
        })
        .catch(() => {
          notificationCall.error({
            message: "更新失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [character, callback, notificationCall]
  );

  return (
    <div>
      {contextHolder}

      <Modal
        title="更新角色"
        open={isModalOpen}
        footer={null}
        width={800}
        onCancel={hideModal}
      >
        <Form
          name="basic"
          layout="vertical"
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            label="分类"
            name="school"
            rules={[{ required: true, message: "请选择分类!" }]}
          >
            <Select defaultValue={character?.school} style={{ width: 120 }} options={options} />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            更新
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Role);
