import { memo } from "react";

const Copyright = () => (
      <footer className="fixed bottom-0 w-full  flex  justify-center text-white p-4     text-sm">
        <div>
          &copy;2023 <strong className={`font-logo `}>Chapose</strong> All rights reserved.
           <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">蜀ICP备2023021488号-1</a>
        </div>
      </footer>
    )
  
  export default memo(Copyright);
