import React, { useState, useEffect, useCallback } from "react";

interface Props {
  onResend: () => void;
}
let interval: any = null;

const Timer = ({ onResend }: Props) => {
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((second) => {
          if (second === 1) {
            clearInterval(interval);
            setIsActive(false);
          }
          return second - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const onClick = useCallback(() => {
    setIsActive(true);
    setSeconds(60);
    onResend();
  }, [onResend]);
  return (
    <div >
      {seconds !== 0 ? (
        <p className="text-gray-400">{seconds}秒后可重新发送</p>
      ) : (
        <p className="cursor-pointer text-gray-100" onClick={onClick}>
          重新发送
        </p>
      )}
    </div>
  );
};
export default Timer;
