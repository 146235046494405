// 写一个hooks，用来获取当前聊天的id
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export const useCharacterId = () => {
  const params = useParams<{ id: string }>();
  const characterId = useMemo(() => {
    if (params?.id) {
      return params.id;
    }
    return null;
  }, [params.id]);
  return { 
    characterId, 
 };
};
